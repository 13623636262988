/**=====================
	4.1. Dark CSS Start
==========================**/
body {
  &.dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;

    p {
      color: $dark-all-font-color;
    }

    .page-body {
      .bg-light {
        background-color: $dark-background !important;
      }
      .card {
        .card-body,
        .card-footer {
          color: $dark-all-font-color;
        }
      }
      .bg-light {
        background-color: #21283b !important;
        color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
      }
    }
    .form-control {
      background-color: $dark-card-background;
      border-color: $dark-card-border !important;
      color: $dark-all-font-color !important;
      &::placeholder {
        color: $dark-all-font-color;
      }
    }
    .wizard-4 {
      .step-container {
        background-color: $dark-card-background;
        border-color: $dark-card-border;
      }
      .login-card {
        .login-main {
          background-color: $dark-body-background;
        }
      }
    }
    .modal {
      .modal-header {
        .product-box {
          .product-details {
            .product-size {
              button {
                color: $dark-all-font-color;
                border-color: $dark-card-border;
              }
            }
          }
        }
      }
    }
    .custom-floating-wrapper {
      .form-label {
        color: $dark-all-font-color !important;
      }
    }
    .invoice-1 {
      .table-borderless {
        td {
          color: $dark-all-font-color;
          border-color: $dark-card-border;
          .media {
            img {
              filter: invert(1);
            }
          }
        }
      }
    }
    .invoice-2 {
      .table-borderless {
        td {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border;
          .media {
            img {
              filter: invert(1);
            }
          }
        }
      }
    }
    .invoice-3 {
      .table-borderless {
        td {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border;
          ul {
            li {
              &.list-group-item {
                h4 {
                  color: $dark-all-font-color !important ;
                }
              }
            }
          }
        }
      }
    }
    .invoice-4 {
      .table-borderless {
        tbody {
          tr {
            td {
              background-color: $dark-card-background !important;
              color: $dark-all-font-color !important ;
            }
          }
        }
        td {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border;
          ul {
            li {
              &.list-group-item {
                h4 {
                  color: $dark-all-font-color !important ;
                }
              }
            }
          }
        }
      }
    }
    .invoice-5 {
      .table-borderless {
        tbody {
          tr {
            td {
              background-color: $dark-card-background !important;
              color: $dark-all-font-color !important ;
              ul {
                li {
                  &.list-group-item {
                    background-color: unset !important;
                  }
                }
              }
            }
          }
        }
        td {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border;
          ul {
            li {
              &.list-group-item {
                h4 {
                  color: $dark-all-font-color !important ;
                }
              }
            }
          }
        }
      }
    }

    .total-balance {
      background-blend-mode: overlay;
    }
    .rmdp-calendar {
      .rmdp-header {
        sapn {
          color: $dark-all-font-color !important;
        }
      }
      .rmdp-header-values {
        color: $dark-all-font-color;
      }
      .rmdp-day span {
        color: $white !important;
      }
      .rmdp-ep-arrow {
        border-color: $dark-body-background !important;
      }
    }

    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .bookmark-flip {
              .bookmark-dropdown {
                .bookmark-content {
                  span {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .onhover-show-div {
              .dropdown-title {
                color: $dark-all-font-color;
              }
              ul {
                li {
                  .d-flex {
                    .flex-grow-1 {
                      h5 {
                        a {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $dark-all-font-color;
    }
    label {
      color: $dark-all-font-color;
    }
    .icon-state {
      .switch-state {
        &:after {
          color: $dark-all-font-color;
        }
      }
    }
    .checkout {
      .form-control {
        background-color: $dark-body-background !important;
        color: $dark-all-font-color !important;
        border: none;
      }
    }
    .sidebar-toggle {
      stroke: $dark-all-font-color !important;
    }
    .knowledgebase-search {
      h3 {
        color: $theme-font-color;
      }
    }
    .table-responsive {
      .rdt_TableHead {
        .rdt_TableHeadRow {
          background-color: $dark-card-background;
          outline: none;
          color: $dark-all-font-color;
          &:hover {
            border: none;
            outline: none;
          }
        }
      }
      .rdt_TableBody {
        .rdt_TableRow {
          &:nth-child(odd) {
            color: $dark-all-font-color;
            outline: none;
            background-color: $dark-body-background;
            &:hover {
              border: none;
              outline: none;
            }
          }
        }
        .rdt_TableRow {
          &:nth-child(even) {
            color: $dark-all-font-color;
            background-color: $dark-card-background;
            &:hover {
              border: none;
              outline: none;
            }
          }
        }
      }
      .rdt_Pagination,
      header {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
        border-top-color: rgba($white, 0.1);
      }
    }
    .rdt_Pagination {
      button {
        fill: $dark-all-font-color;
      }
    }
    .page-wrapper {
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .list-group-item {
                border: none;
                background-color: unset !important;
                .list-group {
                  border: none;
                  .sidebar-list {
                    &.list-group-item {
                      border: none;
                      background-color: unset !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .left-header {
        background-color: $dark-body-background;
      }
      .page-header {
        .header-wrapper {
          .nav-right {
            > ul {
              > li {
                border: none;
                background-color: $dark-body-background;
                svg {
                  fill: #9498de;
                }
              }
            }
          }
        }
      }
      .page-body-wrapper {
        .page-title {
          .row {
            h3 {
              color: $dark-all-font-color;
            }
          }
        }
        div.sidebar-wrapper {
          &.close_icon {
            &:hover {
              .sidebar-main {
                .sidebar-links {
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          .menu-box {
                            background-color: $dark-body-background;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      .menu-box {
                        background-color: $dark-body-background;
                        border-radius: 8px;
                        padding: 0 14px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .left-header {
      .left-menu-header {
        ul {
          &.header-left {
            li {
              .form-group {
                .Typeahead--twitterUsers {
                  .u-posRelative {
                    background-color: $dark-body-background;
                    .form-control-plaintext {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
              ul {
                &.onhover-show-div {
                  background-color: $dark-card-background;
                  &::before {
                    border-bottom: 7px solid $dark-card-background;
                  }
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .apexcharts-tooltip.light {
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        color: $white;
      }
    }
    .apexcharts-tooltip.apexcharts-theme-light {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
    }
    .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
      color: $dark-all-font-color;
    }
    .apexcharts-tooltip-series-group {
      color: $dark-all-font-color !important;
    }
    .apexcharts-yaxistooltip,
    .apexcharts-xaxistooltip {
      color: $dark-all-font-color;
      background: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .apexcharts-yaxistooltip-left {
      &::before,
      &::after {
        border-left-color: $dark-card-border;
      }
    }
    .apexcharts-xaxistooltip-bottom {
      &::before,
      &::after {
        border-bottom-color: $dark-card-border;
      }
    }
    .bottom-content {
      .apexcharts-canvas {
        span {
          color: $dark-all-font-color;
        }
      }
    }
    .apexcharts-canvas {
      .apexcharts-tooltip {
        border: 1px solid $dark-card-border;
        .apexcharts-tooltip-title {
          margin-bottom: 0;
        }
        .apexcharts-tooltip-series-group {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
      .apexcharts-yaxis {
        text {
          fill: $dark-small-font-color;
        }
      }
    }
    .apexcharts-xaxistooltip {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
      border: 1px solid $dark-card-border;
    }
    .apexcharts-xaxistooltip-bottom:before {
      border-bottom-color: $dark-card-border;
    }
    .apexcharts-xaxistooltip-bottom:after {
      border-bottom-color: $dark-card-border;
    }
    .apexcharts-tooltip.apexcharts-theme-light {
      border: 1px solid $dark-card-border;
      background: $dark-body-background;
      .apexcharts-tooltip-text {
        color: $dark-small-font-color;
      }
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        border-bottom: 1px solid $dark-card-border;
        color: $dark-small-font-color;
      }
    }
    .apexcharts-yaxis {
      text {
        color: $dark-all-font-color;
      }
    }
    .invoice-2 {
      tr {
        &.back {
          background-color: $dark-card-background !important;
          td {
            background-color: $dark-card-background !important;
          }
        }
      }
    }
    .invoice-4 {
      tr {
        &.back {
          td {
            background-color: $dark-card-background !important;
          }
        }
      }
    }
    .invoice-5 {
      .back {
        td {
          background-color: $dark-card-background !important;
        }
        background-color: $dark-card-background !important;
      }
    }
    .social-widget {
      .social-icons {
        box-shadow: 0px 42.1092px 27.8569px $dark-body-background,
          0px 8.90772px 7.12618px $dark-body-background,
          0px 2.02448px 3.44162px $dark-body-background;
      }
    }
    .rbt-input-multi {
      .rbt-input-main {
        color: $dark-all-font-color;
      }
    }
    .price-wrapper {
      .radio-wrapper {
        li {
          border: none !important;
        }
      }
    }
    .rbt-token-remove-button {
      filter: unset !important;
    }
    .rbt-input-wrapper {
      .rbt-token-removeable {
        background-color: $dark-background !important;
        color: $dark-all-font-color;
      }
      .rbt-close-content {
        color: $dark-all-font-color;
      }
    }
    .editor-toolbar {
      button {
        &.active {
          background-color: $dark-background !important;
          border-color: $dark-card-border;
        }
        &:hover {
          border-color: $dark-card-border;
          background-color: $dark-background !important;
        }
      }
      i {
        color: $dark-all-font-color;
      }
    }
    .right-sidebar-chat {
      .right-sidebar-Chats {
        .msger-inputarea {
          .dropdown {
            .dropdown-menu {
              .dropdown-item {
                background-color: $dark-card-background !important;
                svg {
                  fill: rgba($white, 0.4);
                }
                &:nth-child(2) {
                  svg {
                    stroke: rgba($white, 0.5);
                  }
                }
              }
            }
          }
        }
      }
    }
    .calendar-basic {
      .md-sidebar-aside {
        background: $dark-card-background;
      }
      #external-events {
        border-color: $dark-card-border;
      }
      .calendar-default {
        .fc {
          .fc-daygrid-day-number,
          .fc-col-header-cell-cushion {
            color: $dark-all-font-color;
          }
        }
      }
    }

    .project-dashboard {
      .mobile-app {
        .user-details {
          ul {
            li {
              + {
                li {
                  background-color: unset;
                }
              }
              img {
                background-color: unset;
              }
            }
          }
        }
      }
      .page-wrapper {
        .page-header {
          .header-wrapper {
            .serchinput {
              .search-form {
                input {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-body-background;
                }
              }
            }
          }
        }
      }

      .project-summary {
        .rdt_Table {
          .rdt_TableHead {
            .rdt_TableHeadRow {
              border-color: $dark-body-background;
              .rdt_TableCol {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
              }
            }
          }
          .rdt_TableBody {
            .rdt_TableRow {
              background-color: $dark-color;
              .rdt_TableCell {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
          }
        }
        .rdt_Pagination {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-top: 1px solid $dark-color;
          button {
            fill: $dark-all-font-color;
          }
        }
      }
    }
    .customers {
      ul {
        li {
          &.list-group-item {
            background-color: unset;
          }
        }
      }
    }
    .clockpicker-popover {
      .popover-title {
        background-color: $dark-card-background;
      }
      &.bottom {
        > .arrow {
          border-bottom-color: $dark-body-background;
          &:after {
            border-bottom-color: $dark-card-background;
          }
        }
      }
      &.top {
        > .arrow {
          border-top-color: $dark-body-background;
          &::after {
            border-top-color: $dark-card-background;
          }
        }
      }
      &.left {
        > .arrow {
          border-left-color: $dark-body-background;
          &::after {
            border-left-color: $dark-card-background;
          }
        }
      }
      .popover-title {
        border-bottom-color: $dark-card-background;
      }
    }
    .edit-profile {
      .form-control {
        background-color: $dark-body-background !important;
        border: none;
        color: $dark-all-font-color;
        &::placeholder {
          color: $dark-all-font-color;
        }
      }
    }
    .fc-theme-standard {
      .fc-scrollgrid,
      .fc-list {
        border-color: $dark-card-border;
      }
      .fc-list-day-cushion {
        background-color: $dark-body-background;
      }
    }
    .timeline-calendar {
      .react-calendar-timeline {
        .rct-items {
          .rct-item {
            border-top-color: $dark-card-border !important;
            border-bottom-color: $dark-card-border !important;
            border-right-color: $dark-card-border !important;
          }
        }
        .rct-dateHeader {
          span {
            color: $white;
          }
        }
        .rct-horizontal-lines {
          .rct-hl-even {
            border-bottom: 1px dashed $dark-card-border !important;
          }
          .rct-hl-odd {
            border-bottom: 1px dashed $dark-card-border !important;
          }
        }
      }
      .rct-item-content {
        color: $white !important;
      }
    }
    .fc {
      .fc-list-event {
        &:hover {
          td {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .view-container {
      .apexcharts-canvas {
        .apexcharts-point-annotations {
          circle {
            outline: 25px solid #3e3d7259;
          }
        }
      }
    }
    div {
      &:where(.swal2-container) {
        div {
          &:where(.swal2-popup) {
            color: $dark-all-font-color;
            background-color: $dark-body-background;
          }
        }
      }
      .swal2-html-container {
        .table-responsive {
          table {
            td {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .rdt_Table {
      svg {
        color: rgba($white, 0.8);
      }
      .badge-light-info {
        background-color: $dark-body-background;
        color: $white;
      }
    }
    .rdt_Pagination {
      .dataTables_wrapper {
        button {
          background-color: $dark-body-background;
        }
      }
    }
    .dataTables_wrapper {
      button {
        background-color: $dark-card-background;
      }
    }
    // dashboard-1
    .selling-product {
      table {
        tr {
          td {
            .d-flex {
              .flex-grow-1 {
                a {
                  span {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .transaction-history {
      table {
        tr {
          td {
            .d-flex {
              .flex-grow-1 {
                a {
                  span {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    // Dashboard-2
    .ecommerce-dashboard {
      .total-sales {
        .d-flex {
          span {
            background: var(--theme-default);
          }
          svg {
            fill: $white;
          }
          &.up-sales {
            span {
              background: var(--theme-secondary);
            }
          }
          &.total-customer {
            span {
              background: $success-color;
            }
          }
          &.total-product {
            span {
              background: $info-color;
            }
          }
        }
      }
    }
    // Dashboard-3
    .project-dashboard {
      .revenue-chart {
        .apexcharts-bar-area {
          stroke: $dark-card-background;
        }
      }
    }
    .track-order {
      li {
        &:after {
          background-color: $dark-body-background;
        }
        .d-flex {
          .flex-shrink-0 {
            background-color: $dark-body-background;
          }
          .flex-grow-1 {
            h5 {
              color: rgba($white, 0.8);
            }
          }
        }
        &:last-child {
          .d-flex {
            .flex-grow-1 {
              padding-bottom: 0px;
              border-bottom: none;
            }
          }
        }
      }
    }
    .mobile-app {
      .client-budget {
        ul {
          li {
            &.list-group-item {
              background-color: unset !important;
            }
            .d-flex {
              .flex-grow-1 {
                a {
                  span {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    .visitors {
      .progress-value {
        span {
          color: $dark-all-font-color;
        }
      }
    }

    .general-widget {
      .total-sales {
        .d-flex {
          span {
            background: var(--theme-default);
          }
          svg {
            fill: $white;
          }
          &.up-sales {
            span {
              background: var(--theme-secondary);
            }
          }
          &.total-customer {
            span {
              background: $success-color;
            }
          }
          &.total-product {
            span {
              background: $info-color;
            }
          }
        }
      }
    }
    // online course dashboard css start
    svg.f-light {
      fill: rgba($white, 0.6);
      opacity: 1;
    }
    .course-widget {
      .btn-light {
        background-color: $dark-body-background !important;
      }
    }
    .schedule-list {
      > li {
        box-shadow: 4px 7px 17px 5px rgba($dark-body-background, 0.2);
      }
    }
    .upcoming-event-wrap {
      .apexcharts-canvas {
        .apexcharts-data-labels {
          rect {
            fill: none;
            stroke: none;
          }
        }
      }
    }
    // online course dashboard  css end
    .page-wrapper {
      &.advance-layout {
        .page-header {
          background: $dark-card-background;
        }
        .page-body-wrapper {
          .sidebar-wrapper {
            border-bottom: 1px solid $dark-card-border;
          }
        }
      }
      &.only-body {
        background: $dark-body-background;
      }
    }
    .page-wrapper.horizontal-wrapper {
      .page-body-wrapper {
        .sidebar-wrapper {
          .sidebar-main {
            .sidebar-links {
              .simplebar-wrapper {
                .simplebar-mask {
                  .simplebar-content-wrapper {
                    .simplebar-content {
                      li {
                        &:hover {
                          ul {
                            li {
                              &:hover {
                                .nav-sub-childmenu {
                                  color: $dark-all-font-color;
                                  background-color: $dark-card-background;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .balance-card {
      .svg-box {
        background: $dark-card-background;
        box-shadow: none;
        svg {
          fill: $dark-all-font-color;
        }
      }
    }
    .f-light {
      color: $dark-all-font-color;
    }
    .apexcharts-tooltip.light {
      .apexcharts-tooltip-title {
        background: $dark-card-background;
        color: $white;
      }
    }
    .card {
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;
    }
    .blog-single {
      .comment-box {
        ul {
          li {
            &.list-group-item {
              background-color: unset;
            }
          }
        }
      }
    }
    .ecommerce-widget {
      border: 1px solid $dark-card-border !important;
      .support-ticket-font {
        ul {
          li {
            &.list-group-item {
              background-color: unset;
            }
          }
        }
      }
    }
    .btn-light {
      color: $dark-all-font-color !important;
    }
    .form-select {
      color: $dark-all-font-color;
    }
    .add-project {
      .text-inherit {
        color: $dark-all-font-color;
      }
    }
    .knowledgebase-search {
      color: $theme-body-font-color;
      .form-control-plaintext {
        color: $theme-body-font-color;
      }
    }
    // Toasts
    .toast {
      border: 1px solid $dark-card-border;
      .toast-header {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color;
        border-radius: 0px;
        background-clip: unset;
        border-bottom: 1px solid rgba($dark-card-border, 0.1);
      }

      .toast-dark {
        background-color: $dark-card-background;
        color: $dark-all-font-color !important;
        .border-top {
          background-color: unset;
          background-clip: unset;
          border-top: 1px solid rgba($dark-card-border, 0.1) !important;
        }
      }
    }
    .btn-close {
      filter: brightness(0.8) invert(1);
    }
    .prooduct-details-box {
      .d-flex {
        border: 1px solid $dark-card-border;
      }
    }
    .product-price {
      del {
        color: $dark-small-font-color;
      }
    }
    .ProfileCard {
      border: 1px solid $dark-card-border !important;
    }
    .form-control-plaintext {
      color: $dark-all-font-color;
    }
    .form-select {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .box-layout {
      background-color: $dark-body-background;
      .page-wrapper,
      &.page-wrapper {
        .page-header {
          .header-wrapper {
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .square-timeline {
      &::before {
        border: 1px dashed $dark-card-border;
      }
    }
    .form-check-input {
      background-color: $dark-body-background;
    }
    .ck.ck-editor__main {
      > .ck-editor__editable {
        background: $dark-body-background;
        border-radius: 0;
      }
    }
    .ck.ck-reset_all {
      svg {
        color: $dark-all-font-color;
      }
      .ck-dropdown__panel {
        .ck-list {
          .ck-list__item {
            color: $dark-all-font-color;
            background-color: $dark-body-background;
          }
        }
      }
    }
    .ck-rounded-corners {
      .ck.ck-editor__main {
        > .ck-editor__editable {
          background-color: $dark-body-background;
          border-color: rgba($white, 0.3);
        }
      }
    }
    .ck.ck-editor__main {
      > .ck-editor__editable.ck-rounded-corners {
        background-color: $dark-body-background;
        border-color: rgba($white, 0.3);
      }
    }

    .ck.ck-button {
      &:not(.ck-disabled) {
        &:hover {
          background: $dark-card-background;
        }
      }
    }

    a.ck.ck-button {
      &:not(.ck-disabled) {
        &:hover {
          background: $dark-card-background;
        }
      }
    }
    .ck-responsive-form {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .ck-labeled-field-view__status {
        color: $dark-all-font-color;
      }
    }
    .ck-dropdown__panel_se {
      .ck {
        background-color: $dark-body-background;
      }
    }
    .ck-editor {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
      .ck-button {
        &:hover {
          color: $dark-all-font-color;
          background-color: $dark-card-background;
        }
        &:active {
          color: $dark-all-font-color;
          background-color: $dark-card-background;
        }
      }
      .ck-button.ck-dropdown__button {
        .ck-button__label {
          color: rgba($white, 0.6);
        }
        &:hover {
          background-color: $dark-body-background;
          color: rgba($white, 0.3);
        }
      }
      .ck.ck-button.ck-on,
      a.ck.ck-button.ck-on {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
      .ck-editor__top {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
        .ck-button__label {
          color: $dark-all-font-color;
        }
      }
      .ck-toolbar_grouping {
        background-color: $dark-body-background;
      }
      .ck-sticky-panel__content {
        border: $dark-card-border;
      }

      .ck-editor__main {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
      .ck-toolbar__items {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
    }
    .icon-lists {
      div {
        &:hover {
          background-color: $dark-body-background;
        }
      }
    }
    .cd-timeline {
      &::before {
        content: "";
        background-color: $dark-body-background;
      }
    }
    .square-timeline {
      .timeline-event {
        .timeline-event-wrapper {
          .timeline-thumbnail {
            background-color: $dark-body-background;
            color: $dark-all-font-color !important;
          }
        }
        &:hover {
          .timeline-thumbnail {
            color: $dark-body-background !important;
          }
        }
      }
    }

    // Radio and checkbox control
    .checkbox-wrapper,
    .radio-wrapper {
      li {
        .form-check-input {
          background-color: transparent;
        }
      }
    }
    .switch-state {
      &:before {
        background-color: $dark-card-background;
      }
    }
    // tooltip css start
    // tooltip css start
    .tooltip {
      &.bs-tooltip-top {
        .tooltip-arrow {
          &:before {
            border-top-color: $dark-card-inbox;
          }
        }
      }
      &.bs-tooltip-bottom {
        .tooltip-arrow {
          &:before {
            border-bottom-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }
      &.bs-tooltip-start {
        .tooltip-arrow {
          &:before {
            border-left-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }
      &.bs-tooltip-end {
        .tooltip-arrow {
          &:before {
            border-right-color: $dark-card-inbox;
            border-top-color: $transparent-color;
          }
        }
      }
      .tooltip-inner {
        background-color: $dark-card-inbox;
        color: $white;
      }
      .tooltip-arrow {
        &:before {
          border-top-color: $dark-card-inbox;
        }
      }
    }
    // tooltip css end
    .page-wrapper {
      &.only-body {
        .page-body-wrapper {
          .page-title {
            > .row {
              .col-6 {
                &:first-child {
                  h6 {
                    color: rgba($dark-all-font-color, 0.5);
                  }
                }
              }
            }
          }
        }
        .page-header {
          .header-wrapper {
            .search-full {
              .form-group {
                .Typeahead {
                  .u-posRelative {
                    background-color: $dark-body-background !important;
                    .search-bg {
                      background-color: $dark-card-background;
                    }
                    .Typeahead-input {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .page-header {
        .header-wrapper {
          .nav-right {
            .profile-dropdown li span {
              color: $white;
            }
            .flip-card .flip-card-inner {
              .back {
                background-color: $dark-body-background !important;
                .flip-back-content {
                  input {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                    color: $dark-all-font-color;
                  }
                }
                li {
                  &:last-child {
                    border-top: 1px solid $dark-card-border;
                  }
                }
              }
              .front {
                background-color: $dark-body-background !important;
                svg {
                  stroke: $white;
                }
              }
            }
            .cart-dropdown {
              .total {
                background-color: $dark-card-background;
              }
              .qty-box {
                .input-group {
                  .btn {
                    color: rgba($white, 0.4);
                    border-color: $dark-card-background !important;
                    background-color: $dark-card-background !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .qty-box {
      input {
        color: rgba($white, 0.5);
      }
    }
    .customizer-contain {
      color: $dark-body-background;
      .customizer-body {
        .main-layout {
          .box-layout {
            background-color: $white;
          }
        }
      }
    }
    .breadcrumb-item {
      &.active {
        color: $dark-all-font-color;
      }
      + .breadcrumb-item {
        &::before {
          color: unset;
        }
      }
    }
    .browse {
      .browse-articles {
        h4 {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .rating.pill-rating-list {
      .pill-rating {
        background-color: $dark-body-background;
      }
    }
    .rating {
      .square-number {
        background-color: $dark-body-background;
      }
      .br-widget {
        background-color: $dark-body-background;
      }
      .br-selected {
        background-color: $dark-body-background;
      }
    }

    .comment-box {
      .d-flex {
        img {
          border: 1px solid $dark-card-border;
        }
      }
      .comment-social {
        li {
          color: $gray-60;
          &:first-child {
            border-right: 1px solid $light-semi-gray;
          }
        }
      }
    }
    .cart-dropdown {
      li {
        .d-flex {
          .flex-grow-1 {
            span {
              color: rgba($white, 0.8) !important;
            }
          }
        }
        .view-cart,
        .view-checkout {
          color: rgba($white, 0.8) !important;
        }
      }
    }
    .category-buton {
      a {
        color: $dark-all-font-color;
      }
    }
    .vertical-main-wizard {
      .header-vertical-wizard {
        background-color: $dark-body-background;
      }
    }
    .msger-chat {
      background: $dark-body-background;
    }
    .left-msg {
      .msg-bubble {
        background: $dark-card-background;
        color: rgba($white, 0.6);
      }
    }
    .contact-edit {
      &.chat-alert {
        background: $dark-body-background;
      }
      i {
        color: rgba($white, 0.6);
      }
    }
    .right-msg {
      .msg-bubble {
        color: rgba($white, 0.6);
      }
    }
    .right-sidebar-title {
      .common-space {
        .chat-time {
          div {
            p {
              color: rgba($white, 0.6);
            }
          }
        }
      }
    }
    .msger-inputarea {
      background: $dark-card-background;
      .dropdown-form {
        .chat-icon {
          background-color: unset !important;
        }
      }
    }
    .dropdown-basic {
      .dropdown {
        .dropdown-menu {
          li {
            &.list-group-item {
              border: none !important;
              background-color: unset;
            }
            button {
              &.dropdown-item {
                padding: 8px 0px;
              }
            }
          }
        }
        .dropdown-content {
          a {
            background-color: $dark-body-background;
            border-color: $dark-card-border;
            color: $dark-all-font-color;
            opacity: 1;
            &:hover {
              opacity: 1;
              color: $dark-all-font-color !important;
              background-color: $dark-body-background !important;
            }
          }
          button {
            opacity: 1;
            background-color: $dark-body-background;
            border-color: $dark-card-border;
            color: $dark-all-font-color;
            &:hover {
              opacity: 1;
              color: $dark-all-font-color;
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .custom-progress-wrapper {
      .progress-btn {
        color: $white !important;
        background-color: $dark-body-background;
        border-color: $dark-card-border;
      }
    }
    .left-sidebar-wrapper {
      .advance-options {
        .chats-user {
          li:hover {
            background-color: $dark-body-background;
          }
        }
        .contact-wrapper {
          p {
            color: $dark-all-font-color;
          }
        }
        .common-space {
          p {
            color: $dark-all-font-color;
          }
          .chat-time {
            span {
              color: $dark-all-font-color;
            }
          }
        }
        ul {
          .nav-item {
            .nav-link {
              color: rgba($white, 0.8);
            }
          }
        }
      }
    }

    .login-dark {
      background-color: $dark-body-background;
      background-blend-mode: overlay;
      .login-main {
        background-color: $dark-card-background;
      }
    }
    .badge-spacing {
      .badge.badge {
        svg {
          stroke: rgba($white, 0.6) !important;
        }
      }
      .bg-light {
        color: $dark-all-font-color !important;
      }
      .badge-light {
        color: $dark-all-font-color !important;
        background-color: $dark-body-background !important;
      }
    }
    .login-card {
      .btn-showcase .btn {
        background-color: $dark-card-background !important;
        color: $white;
        border-color: $dark-card-background !important;
      }
      .login-main {
        box-shadow: 0 0 37px rgba($white, 0.05);
        .theme-form {
          input {
            background-color: $dark-body-background !important;
          }
          .or {
            &:before {
              background-color: $dark-card-background;
            }
          }
          .checkbox label::before {
            background-color: $dark-body-background !important;
            border-color: $dark-card-background !important;
          }
          .text-muted {
            color: $dark-all-font-color !important;
          }
          .or {
            &:before {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .H_ui {
      ~ div {
        background-color: $dark-body-background !important;
      }
    }
    .swal-icon--success:after,
    .swal-icon--success:before {
      background: $dark-card-background;
    }
    .swal-icon--success__hide-corners {
      background-color: $dark-card-background;
    }
    .note-editor.note-frame {
      .note-status-output {
        border-top: 1px solid $dark-card-background;
      }
      .note-statusbar {
        border-top: 1px solid $dark-card-background;
        .note-resizebar {
          background-color: $dark-card-background;
        }
      }
    }
    .light-font {
      color: rgba(255, 255, 255, 70%);
    }
    .page-link {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .b-r-light {
      border-right: 1px solid $dark-card-border !important;
    }
    .history-details {
      .d-flex {
        border-top: 1px solid $dark-card-border;
      }
    }
    .modal-header {
      border-bottom: 1px solid $dark-card-border;
    }
    #right-history {
      background-color: $dark-card-background;
      box-shadow: 0 0 9px $dark-body-background;
      h6 {
        span {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .ProfileCard {
      &:hover {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
    }
    .translate_wrapper.active .more_lang {
      &:before {
        border-bottom: 7px solid $dark-card-background;
      }
      .lang {
        border-bottom: 1px solid $dark-body-background;
      }
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .nav-right {
            .profile-dropdown {
              li {
                svg {
                  stroke: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .page-body-wrapper {
        background-color: $dark-body-background;
        .sidebar-main {
          .sidebar-links {
            .sidebar-link.active {
              svg {
                &.stroke-icon {
                  stroke: var(--theme-default);
                }
              }
            }
            .simplebar-wrapper {
              .simplebar-mask {
                .simplebar-content-wrapper {
                  .simplebar-content {
                    > li {
                      .mega-menu-container {
                        .mega-box {
                          .link-section {
                            .submenu-title {
                              h5 {
                                color: $dark-all-font-color;
                              }
                            }
                            .submenu-content {
                              &.opensubmegamenu {
                                li {
                                  a {
                                    color: $dark-all-font-color;
                                    font-weight: 400;
                                  }
                                }
                              }
                            }
                          }
                        }
                        &::after {
                          background-color: $dark-all-font-color;
                        }
                      }
                      .sidebar-submenu {
                        li {
                          a {
                            color: $dark-all-font-color;
                            &.active {
                              color: var(--theme-default);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .sidebar-list {
              ul.sidebar-submenu {
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }
                    &.active {
                      color: var(--theme-default);
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.modern-type {
        .page-body-wrapper {
          .page-title {
            background-color: transparent;
            border: none;
          }
        }
      }
      .sidebar-main-title {
        p {
          color: $dark-all-font-color;
        }
      }
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            .sidebar-main {
              .sidebar-links {
                .sidebar-link.active {
                  svg {
                    stroke: var(--theme-default);
                  }
                }
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        > li {
                          .mega-menu-container {
                            .mega-box {
                              .link-section {
                                .submenu-title {
                                  h5 {
                                    color: $dark-all-font-color;
                                  }
                                }
                                .submenu-content {
                                  &.opensubmegamenu {
                                    li {
                                      a {
                                        color: $dark-all-font-color;
                                        font-weight: 400;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &::after {
                              background-color: $dark-all-font-color;
                            }
                          }
                          .sidebar-submenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active {
                                  color: var(--theme-default);
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sidebar-list {
                  ul.sidebar-submenu {
                    li {
                      a {
                        span {
                          color: $dark-all-font-color;
                        }
                        &.active {
                          color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            background: $dark-body-background;
            border-top: 1px solid $dark-card-border;
            .sidebar-main {
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        li {
                          &.list-group-item {
                            border: none;
                            background-color: unset !important;
                          }
                          background-color: transparent;
                        }
                        .menu-box {
                          background-color: transparent;
                          li {
                            a {
                              color: $sidebar-fill-icon-color;
                            }
                          }
                          ul {
                            .sidebar-list {
                              &.hoverd {
                                .sidebar-submenu {
                                  li {
                                    .opensubmegamenu {
                                      background-color: $dark-card-background;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                        li {
                          &:hover {
                            ul {
                              li {
                                &:hover {
                                  background: $dark-card-background !important;
                                }
                              }
                            }
                          }
                        }
                        > li {
                          > a {
                            color: $dark-all-font-color;
                          }
                          .sidebar-submenu {
                            background: $dark-card-background;
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active,
                                &:hover {
                                  color: var(--theme-default) !important;
                                }
                              }
                              .opensubmegamenu {
                                background: $dark-card-background;
                              }
                            }
                          }
                          .mega-menu-container {
                            background: $dark-card-background;
                            .mega-box {
                              .link-section {
                                .submenu-content {
                                  li {
                                    a {
                                      color: $dark-all-font-color;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .left-arrow,
              .right-arrow {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      &.compact-sidebar {
        .page-body-wrapper {
          div {
            &.sidebar-wrapper {
              > div {
                background: $dark-card-background;
              }
              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-title {
                      border-bottom: 1px solid $dark-card-border;
                    }
                    a {
                      span {
                        color: $dark-all-font-color;
                      }
                      svg {
                        stroke: $dark-all-font-color;
                      }
                    }
                    .sidebar-submenu,
                    .mega-menu-container {
                      background-color: $dark-card-background;
                      li {
                        a {
                          border-bottom: 1px solid $dark-card-border;
                        }
                      }
                    }
                  }
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          > li {
                            .sidebar-link {
                              &.active {
                                background-color: rgba($primary-color, 0.2);
                                span {
                                  color: var(--theme-default);
                                }
                                svg {
                                  color: var(--theme-default);
                                  stroke: var(--theme-default);
                                }
                              }
                            }
                            .mega-menu-container {
                              .mega-box {
                                .link-section {
                                  .submenu-title {
                                    h5 {
                                      color: $dark-all-font-color;
                                    }
                                  }
                                  .submenu-content {
                                    &.opensubmegamenu {
                                      li {
                                        a {
                                          color: $dark-all-font-color;
                                          font-weight: 400;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              &::after {
                                background-color: $dark-all-font-color;
                              }
                            }
                            .sidebar-submenu {
                              li {
                                a {
                                  color: $dark-all-font-color;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .sidebar-list {
                    ul.sidebar-submenu {
                      li {
                        a {
                          span {
                            color: $dark-all-font-color;
                          }
                          &.active {
                            color: var(--theme-default);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
      &.material-type {
        .page-header {
          .header-wrapper {
            border-radius: 15px 15px 0 0;
          }
        }
      }
      // material icon css
      &.material-icon {
        .page-header {
          .header-wrapper {
            background-color: $transparent-color;
            .nav-right {
              .language-nav {
                .more_lang {
                  color: $white;
                }
              }
              .onhover-show-div {
                color: $white;
              }
              .flip-card {
                .flip-card-inner {
                  .back {
                    .flip-back-content {
                      input {
                        border: 1px solid $dark-card-border;
                        background: $dark-body-background;
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
          }
          .header-logo-wrapper {
            background-color: $transparent-color;
          }
        }
        .page-body-wrapper {
          .page-body {
            background-color: $transparent-color;
          }
        }
      }
      &.advance-layout {
        background-color: $dark-body-background;
        .page-header {
          .header-wrapper {
            background: $transparent-color;
            .header-logo-wrapper {
              background: $transparent-color;
            }
          }
        }
        .page-body-wrapper {
          background: $transparent-color;
          .page-body {
            background: $transparent-color;
          }
        }
      }
      &.material-icon {
        .page-body-wrapper {
          .page-title {
            background-color: transparent;
            box-shadow: none;
            border: none;
          }
        }
      }
      .page-header {
        .header-wrapper {
          .search-full {
            background-color: $dark-card-background;
            input {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      .md-sidebar {
        .job-sidebar {
          background-color: $dark-card-background;
        }
      }
    }
    @media only screen and (min-width: 768px) {
      .page-wrapper {
        &.material-icon {
          .page-header {
            .header-wrapper {
              .nav-right {
                ul {
                  li {
                    .profile-dropdown {
                      li {
                        svg {
                          stroke: $white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ::-webkit-calendar-picker-indicator {
      filter: invert(1);
    }
    .kanban-board-header {
      background-color: $dark-body-background;
      border-bottom: 1px solid $dark-card-border;
    }
    .dzu-dropzone {
      .dzu-previewContainer {
        border-color: $dark-body-background;
      }
    }
    .kanban-board .kanban-drag {
      background: $dark-body-background;
    }
    .kanban-container {
      .kanban-item {
        .kanban-box {
          border: 1px solid $dark-card-border;
          background-color: $dark-body-background;
        }
      }
    }
    input[type="file"] {
      &::file-selector-button {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
      &:hover:not(:disabled):not([readonly])::file-selector-button {
        color: $dark-all-font-color !important;
        background-color: $dark-body-background !important;
      }
    }
    .project-box {
      border: 1px solid rgba($primary-color, 0.15);
      background-color: $dark-body-background;
    }
    .file-content {
      .form-inline {
        border: 1px solid $dark-card-border;
      }
      .files {
        .file-box {
          border: 1px solid rgba($primary-color, 0.15);
          background-color: $dark-body-background;
          .file-top {
            background-color: $dark-card-background;
            border: 1px solid rgba($primary-color, 0.15);
          }
        }
      }
      .folder .folder-box {
        border: 1px solid rgba($primary-color, 0.15);
        background-color: $dark-body-background;
      }
    }
    .email-wrap {
      .dropdown {
        .dropdown-menu {
          .dropdown-item {
            color: $dark-all-font-color;
            border-color: $dark-card-border;
            background-color: transparent;
          }
        }
      }
    }
    .file-sidebar {
      .list-group {
        .list-group-item {
          border: unset !important;
        }
      }
      .simple-list {
        hr {
          border-top: 1px solid rgba($white, 0.8);
        }
      }
      .pricing-plan {
        border: 1px solid rgba($primary-color, 0.15) !important;
      }
      .btn-light {
        color: $dark-all-font-color;
        background-color: $dark-body-background !important;
        border: 1px solid rgba($primary-color, 0.15) !important;
      }
    }
    #lnb {
      border-right: 1px solid $dark-card-border;
    }
    .lnb-new-schedule,
    .lnb-calendars > div {
      border-bottom: 1px solid $dark-card-border;
    }
    #menu .sidebar-list .btn-default {
      color: rgba($dark-all-font-color, 0.2);
    }
    .tui-full-calendar-timegrid-hour {
      background-color: $dark-card-background;
      color: $white !important;
    }
    .tui-full-calendar-timegrid-gridline {
      border-bottom: 1px solid $dark-card-border !important;
    }
    .tui-full-calendar-time-date,
    .tui-full-calendar-weekday-grid-line,
    .tui-full-calendar-left,
    .tui-full-calendar-timegrid-timezone {
      border-right-color: $dark-card-border !important;
    }
    .tui-full-calendar-popup {
      color: $theme-body-font-color;
    }
    #menu {
      .btn-default {
        color: $dark-all-font-color;
        &:hover {
          color: $theme-body-font-color;
        }
      }
      .dropdown-menu {
        color: $dark-all-font-color;
      }
    }
    .tui-full-calendar-dayname-container,
    .tui-full-calendar-splitter {
      border-top-color: $dark-card-border !important;
      border-bottom-color: $dark-card-border !important;
    }
    span.tui-full-calendar-dayname-date-area {
      color: $white !important;
    }
    .tui-full-calendar-layout {
      background-color: $dark-card-background !important;
    }
    // index page css end
    .contacts-tabs {
      .nav-pills {
        border-right: 1px solid $dark-card-border;
        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-card-border;
          }
        }
      }
    }
    .list-persons {
      .profile-mail {
        .d-flex {
          .flex-grow-1 {
            ul {
              li {
                + li {
                  border-left: 2px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .apexcharts-gridline {
      stroke: $dark-card-border;
    }
    .apexcharts-tooltip-title,
    .apexcharts-tooltip-series-group,
    .apexcharts-tooltip.light,
    .apexcharts-menu-item {
      color: $black;
    }
    // peity chart css
    .small-donut,
    .donut-peity2,
    .pie-peity {
      ~ svg,
      svg {
        path {
          &:nth-child(even) {
            fill: $dark-body-background;
          }
        }
      }
    }
    .timeline-list {
      &.timeline-border {
        border-bottom: 1px solid $dark-card-border;
      }
    }
    // index page css start
    .appointment-table {
      table {
        tr {
          td {
            &.img-content-box {
              .font-roboto {
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .notification {
      .card {
        .d-flex {
          .w-100 {
            p {
              span {
                color: $dark-all-font-color;
              }
            }
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .datepicker {
      background-color: $dark-card-inbox;
      color: $dark-all-font-color;
      .datepicker--cell.-current- {
        color: $primary-color;
      }
      .datepicker--cell.-current-.-focus- {
        color: $white;
      }
      .datepicker--cell-day.-other-month-,
      .datepicker--cell-year.-other-decade- {
        color: rgba($white, 0.4);
      }
      .datepicker--nav {
        border-bottom: 1px solid $dark-card-border;
      }
      .datepicker--pointer {
        background: $dark-card-inbox;
        border-top-color: $dark-card-inbox;
        border-right-color: $dark-card-inbox;
      }
    }
    .default-datepicker {
      .datepicker-inline {
        .datepicker {
          background: $dark-card-background;
          .datepicker--nav-title {
            color: $dark-all-font-color;
            i {
              color: $dark-all-font-color;
            }
          }
          .datepicker--content {
            .datepicker--days {
              .datepicker--days-names,
              .datepicker--cells {
                .datepicker--day-name,
                .datepicker--cell-day {
                  color: $dark-all-font-color;
                  &.-selected- {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    // index page css end
    // ecommerce dashboard css start
    $alert-name: primary, secondary, success, danger, warning, info, light, dark;
    $alert-color: $primary-color, $secondary-color, $success-color,
      $danger-color, $warning-color, $info-color, $light-color, $dark-color;
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var} {
        background-color: rgba(nth($alert-color, $i), 0.8) !important;
        border-color: rgba(nth($alert-color, $i), 0.9) !important;
        color: $white;
        .progress {
          height: 5px;
          background-color: darken(nth($alert-color, $i), 1%);
          border-radius: 0;
        }
        .progress-bar {
          background-color: lighten(nth($alert-color, $i), 50%);
        }
      }
      .alert-#{$var}.inverse {
        background-color: $transparent-color !important;
        .btn-close {
          filter: brightness(1) invert(1);
        }
      }
      .alert-#{$var}.outline,
      .alert-#{$var}.outline-2x {
        .btn-close {
          filter: brightness(1) invert(1);
        }
      }
      .dismiss-text {
        .alert-dismissible {
          .btn-close {
            filter: unset;
            .bg-light {
              color: $theme-body-font-color !important;
            }
          }
        }
      }
    }
    .options {
      > div {
        border: 1px solid $dark-card-border;
      }
    }
    .was-validated {
      .custom-control-input {
        &:invalid {
          ~ .custom-control-label {
            &::before {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .pricing-simple {
      box-shadow: 1px 1px 2px 1px $dark-body-background !important;
    }
    .pricing-block {
      box-shadow: 0 0 10px 5px rgba($primary-color, 0.05) !important;
    }
    .search-page {
      ul {
        &.search-info {
          li {
            + li {
              border-left: 1px solid $dark-body-background;
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-card-border;
        }
      }
    }
    .product-box {
      .product-details {
        h3 {
          color: $dark-all-font-color;
        }
      }
      .modal {
        .modal-header {
          .product-box {
            .product-details {
              .product-size {
                ul {
                  li {
                    color: $dark-all-font-color;
                    border-color: $dark-card-border;
                  }
                }
              }
            }
          }
        }
      }
    }

    .job-search {
      .job-description {
        .theme-form {
          .row {
            div[class^="col-"] {
              .input-group {
                .datepicker-here {
                  border: 1px solid $dark-body-background;
                }
              }
            }
          }
        }
      }
    }
    .sidebar-left-wrapper {
      .sidebar-left-icons {
        .nav-item {
          .nav-link {
            .nav-rounded {
              .product-icons {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .calendar-wrap {
      .fc-unthemed {
        td,
        th {
          border-color: $dark-body-background;
        }
      }
    }
    .mega-inline,
    .mega-horizontal,
    .mega-vertical {
      .card {
        box-shadow: 1px 1px 7px 0 rgba($dark-card-border, 0.5) !important;
      }
      &.plain-style,
      &.border-style,
      &.offer-style {
        .card {
          box-shadow: none !important;
        }
      }
      &.border-style {
        .card {
          border: 1px solid $dark-card-border !important;
        }
      }
      &.offer-style {
        .card {
          border: 1px dashed $dark-card-border !important;
        }
      }
    }
    .setting-dot {
      .setting-bg {
        background-color: var(--theme-secondary);
      }
    }
    .bookmark.pull-right {
      border: none;
    }
    .dropdown-menu.show {
      background-color: $dark-body-background;
    }
    // popover css start
    .popover {
      &.bs-popover-bottom {
        .popover-arrow {
          &:after {
            border-bottom-color: $dark-body-background;
            border-right-color: $transparent-color;
          }
        }
      }
      &.bs-popover-top {
        .popover-arrow {
          &:after {
            border-right-color: $transparent-color;
            border-top-color: $dark-body-background;
          }
        }
      }
      &.bs-popover-start {
        .popover-arrow {
          &:after {
            border-left-color: $dark-body-background;
          }
        }
      }
      background-color: $dark-body-background;
      .popover-header {
        background-color: $dark-card-background;
      }
      .popover-arrow {
        &:after {
          border-right-color: $dark-body-background;
        }
      }
      .popover-body {
        color: rgba(255, 255, 255, 0.6);
      }
      code {
        background-color: unset;
      }
    }
    // popover css end
    // apex chart css start
    .apex-chart-container {
      .apexcharts-legend {
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
      }
    }
    .apexcharts-canvas {
      svg {
        .apexcharts-title-text {
          fill: $white;
        }
        .apexcharts-subtitle-text {
          fill: $white;
        }
        .apexcharts-yaxis {
          .apexcharts-yaxis-texts-g {
            .apexcharts-yaxis-label {
              fill: $white;
            }
          }
        }
        .apexcharts-xaxis {
          .apexcharts-xaxis-texts-g {
            .apexcharts-xaxis-label {
              fill: $white;
            }
          }
        }
        .apexcharts-legend-series {
          span {
            color: $dark-all-font-color !important;
          }
        }
        .apexcharts-datalabels-group {
          .apexcharts-datalabel-label {
            fill: $white;
          }
          .apexcharts-datalabel-value {
            fill: $white;
          }
        }
      }
    }
    .flatpickr-current-month,
    .flatpickr-weekday {
      color: $dark-all-font-color;
    }
    .flatpickr-months {
      .flatpickr-prev-month,
      .flatpickr-next-month {
        fill: $dark-all-font-color;
      }
    }
    .flatpickr-day {
      color: $dark-all-font-color;
      &.prevMonthDay,
      &.nextMonthDay {
        color: $dark-card-inbox;
      }
    }
    // apex chart css end
    .Typeahead-menu {
      background-color: $dark-body-background;
    }

    .Typeahead--twitterUsers {
      .Typeahead-menu {
        .ProfileCard-realName {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .ecommerce-widget {
      border: 1px solid $dark-card-border;
    }
    .page-wrapper {
      .page-header {
        .header-wrapper {
          .serchinput {
            .search-form {
              input {
                background-color: $dark-body-background;
                color: $dark-all-font-color;
              }
              .Typeahead-menu {
                top: 91%;
                min-width: 273px;
                min-height: 210px;
                .ProfileCard-realName {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-card-border;
              }
            }
          }
        }
      }
    }
    .flatpickr-monthDropdown-month {
      background-color: $dark-card-background !important;
    }
    .todo-wrap {
      .card-header {
        &.b-bottom {
          border-bottom: $dark-card-border;
        }
      }
    }
    .todo-wrap {
      .card-header {
        .todo-list-header {
          .new-task-wrapper {
            input {
              color: $dark-all-font-color;
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    .email-wrap {
      .email-top {
        h3 {
          color: $dark-all-font-color;
        }
      }
      .flex-grow-1 {
        h3 {
          color: $dark-all-font-color;
        }
      }
      .email-app-sidebar {
        ul {
          li {
            a {
              > .title {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .main-menu {
          > li {
            a {
              &:hover {
                background-color: $dark-card-background;
                text-decoration: none;
              }
            }
          }
        }
      }

      .email-wrapper {
        h3 {
          color: $dark-all-font-color;
        }
        .text-muted {
          color: $dark-all-font-color !important;
        }
        .actions {
          li {
            color: $dark-all-font-color;
          }
        }
        .theme-form {
          label {
            color: $dark-all-font-color;
          }
        }
        hr {
          border-top: 1px solid $dark-card-border;
        }
      }
      .email-top {
        border-bottom: 1px solid $dark-color;
      }
      .email-right-aside {
        .email-body {
          .inbox {
            .d-flex {
              &.active {
                background-color: $dark-body-background;
              }
            }
          }
        }
      }
    }
    // cart css start
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: #2e3648 !important;
            border-color: $dark-card-border !important;
          }
        }
      }
    }
    .input-group {
      .rmdp-ep-arrow {
        &::after {
          background-color: $dark-card-background;
        }
      }
      .rmdp-ep-arrow[direction="top"] {
        border-bottom: none;
      }
      .rmdp-arrow-container {
        i {
          border-color: $white;
        }
        align-items: center;

        &:hover {
          background-color: $dark-card-background;
        }
      }
      .rmdp-shadow {
        background-color: $dark-card-background;
        input {
          color: rgba($white, 0.6);
          background-color: $dark-card-background;
        }
      }
    }
    // cart css end
    // checkout css start
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
        padding: 40px;
        ul {
          li {
            background-color: unset;
          }
        }
        .animate-chk {
          .radio_animated {
            &:after {
              border: 2px solid $dark-card-background;
            }
          }
        }
      }
    }
    .pricingtable {
      background-color: $dark-body-background;
    }
    // checkout css end
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-card-border;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-card-border;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .dropdown-menu {
      .dropdown-item {
        background-color: unset;
        color: $dark-all-font-color;
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-card-background;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-card-border;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba($primary-color, 0.15);
    }
    .email-wrap {
      p {
        color: $dark-all-font-color;
      }
    }
    .email-body {
      .mail-body-wrapper {
        ul {
          li {
            .inbox-message {
              .email-data {
                span {
                  color: $dark-all-font-color;
                }
                .badge-light-light {
                  background-color: $dark-body-background;
                }
              }
            }
          }
        }
      }
      .light-square {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .email-main-wrapper {
      .email-app-sidebar {
        .nav-pills {
          .nav-link {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .modal-title {
      &.fs-5 {
        color: $dark-all-font-color;
      }
    }
    .custom-input {
      label {
        color: $dark-all-font-color !important;
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-card-border;
      border-bottom: 1px solid $dark-card-border;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-card-border;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-card-border;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .page-wrapper {
      /* Main Header start */
      .page-header {
        .translate_wrapper {
          &.active {
            .more_lang {
              background-color: $dark-body-background;
            }
            .lang {
              background-color: $dark-card-background;
            }
          }
        }
        form {
          background-color: $dark-body-background;
        }
        .header-wrapper {
          background-color: $dark-card-background;
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            &.right-header {
              > ul {
                > li {
                  .profile-media {
                    .flex-grow-1 {
                      p {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 14px $dark-body-background;
                  li {
                    border: none;
                    background-color: $dark-body-background;
                    // border-color: $dark-card-background;
                  }
                  p {
                    color: $dark-all-font-color;
                  }
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  .d-flex {
                    .flex-grow-1 {
                      a {
                        h5 {
                          color: $dark-all-font-color;
                        }
                      }
                    }
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: var(--theme-default);
                    svg {
                      line,
                      path,
                      circle {
                        color: var(--theme-default) !important;
                      }
                    }
                  }
                }
              }
            }
            > ul {
              > li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
              }
            }
            .bookmark-flip {
              .bookmark-dropdown {
                .bookmark-content {
                  .bookmark-icon {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
        }
        .header-logo-wrapper {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
      }
      /* Main Header ends */
      .page-body-wrapper {
        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .flex-grow-1,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .d-flex {
              .flex-grow-1 {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-card-border;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .d-flex {
              .flex-grow-1 {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-card-border;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .d-flex {
              .flex-grow-1 {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .d-flex {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-card-border;
                  }
                }
              }
              .flex-grow-1 {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        .page-title {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb {
            li {
              color: $dark-all-font-color;
            }
            .breadcrumb-item {
              a {
                svg {
                  stroke: $dark-all-font-color;
                }
              }
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .default-according {
            .card:not(.email-body) {
              border: none;
            }
          }
          .card:not(.email-body) {
            background-color: $dark-card-background;
            box-shadow: 1px 1px 2px 1px rgba(29, 28, 28, 0.08);
            border: 1px solid $dark-card-border;
            color: $dark-all-font-color;
            &.widget-1 {
              background-blend-mode: overlay;
              .widget-round {
                .bg-round {
                  box-shadow: 1px 2px 21px -2px rgba(14, 14, 16, 0.83);
                  .half-circle {
                    background: rgba(49, 53, 66, 0);
                  }
                }
              }
            }
            &.social-profile {
              background-blend-mode: overlay;
              background-color: $dark-card-background !important;
            }
            .table {
              &.table-light {
                tr {
                  th,
                  td {
                    color: $theme-body-font-color;
                  }
                }
              }
            }
            table {
              thead,
              tbody {
                .border-bottom-light {
                  th,
                  td {
                    border-bottom: 1px solid $dark-card-border !important;
                  }
                }
              }
            }
            &.trending-card {
              background-color: #010314;
            }
            &.explore-card {
              background-color: $dark-card-background;
              background-blend-mode: overlay;
            }
            // cypto dashboard css start
            &.balance-box {
              background-blend-mode: overlay;
            }
            // cypto dashboard css end
            .chart-block {
              #bar-chart2 {
                svg {
                  > rect {
                    fill: $dark-card-background;
                  }
                  > g {
                    text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
              .word-tree {
                svg {
                  > g {
                    > rect {
                      fill: $dark-card-background;
                    }
                    > text {
                      fill: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .card-body {
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              p {
                color: $dark-all-font-color;
              }
              .gradient-border {
                .font-wrapper {
                  color: $dark-all-font-color;
                }
              }
            }
            .card-header {
              background-color: $transparent-color;
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                background-color: $dark-card-background;
                i {
                  color: $dark-all-font-color;
                  &.fa-cog {
                    color: var(--theme-default);
                  }
                }
              }
              &.note-toolbar {
                background-color: $dark-card-background;
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            thead,
            tbody,
            tfoot,
            tr,
            td,
            th {
              border-color: $dark-card-border;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 1px solid $dark-card-border;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid var(--theme-default);
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $dark-card-background;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid var(--theme-default);
                  }
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    --bs-table-accent-bg: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-double,
            .table-dotted,
            .table-dashed {
              border-left-color: $dark-card-border;
              border-right-color: $dark-card-border;
            }
            .table-bordered {
              border-color: $dark-card-border !important;
              td,
              th {
                border-color: $dark-card-border !important;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-card-border;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
            }
            .switch {
              .switch-state {
                background-color: $dark-body-background;
              }
              input {
                &:checked {
                  + .switch-state {
                    background-color: var(--theme-default);
                  }
                }
              }
            }
            .bg-white {
              background-color: $white !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-card-border !important;
            }
            .ct-grid {
              stroke: $dark-card-border;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-card-border;
            }
            .text-muted {
              color: $dark-all-font-color !important;
            }
            .btn-outline-light,
            .btn-outline-dark,
            .btn-outline-light-2x {
              color: $white !important;
              border: 1px solid $dark-card-border;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }
            .border-right {
              border-right: 1px solid $dark-card-border !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#gantt_chart {
                svg {
                  g {
                    rect {
                      &:first-child {
                        fill: $dark-body-background;
                      }
                    }
                  }
                }
                rect {
                  &:nth-child(6) {
                    fill: $dark-body-background;
                  }
                }
              }
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-card-border !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-card-border;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-card-border;
            }
            .dataTables_wrapper {
              &.no-footer {
                .dataTables_scrollBody {
                  border-bottom: 1px solid $dark-body-background;
                }
              }
              .btn-danger,
              .btn-success,
              .btn-primary {
                color: $white;
              }
              .dataTables_length {
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-card-border;
                }
              }
              .dataTables_length,
              .dataTables_filter,
              .dataTables_info,
              .dataTables_processing,
              .dataTables_paginate {
                color: $dark-all-font-color;
              }
              .dataTables_paginate {
                border: 1px solid $dark-card-border;
              }
              .dataTables_filter {
                input[type="search"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                  color: $dark-all-font-color;
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.stripe {
                tbody {
                  tr {
                    background-color: $dark-card-background;
                    &:hover {
                      > .sorting_1 {
                        background-color: $dark-datatable-sorting;
                      }
                    }
                  }
                  tr.odd {
                    background-color: $dark-datatable-odd;
                    > .sorting_1 {
                      background-color: $dark-datatable-sorting;
                    }
                  }
                  tr.even {
                    > .sorting_1 {
                      background-color: $dark-datatable-sorting-even;
                    }
                  }
                }
              }
              table.dataTable {
                thead {
                  background-color: $dark-body-background;
                  th,
                  td {
                    border-bottom: 2px solid $dark-card-border;
                  }
                }
                input,
                select {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border: 1px solid $dark-card-border;
                }
                tbody {
                  tr {
                    background-color: $dark-card-background;
                  }
                  td.select-checkbox,
                  th.select-checkbox {
                    &:before {
                      border: 1px solid $dark-small-font-color;
                    }
                  }
                }
              }
              .table-striped {
                tbody {
                  tr {
                    &:nth-of-type(odd) {
                      --bs-table-accent-bg: $dark-card-background;
                    }
                  }
                }
              }
              .dataTables_paginate {
                .paginate_button {
                  color: $dark-all-font-color !important;
                  &.current,
                  &:active {
                    border-color: var(--theme-default);
                  }
                }
                .paginate_button.disabled {
                  color: $dark-small-font-color !important;
                  &:hover,
                  :active {
                    color: $dark-small-font-color !important;
                  }
                }
              }
              table.dataTable.row-border,
              table.dataTable.display {
                tbody {
                  th,
                  td {
                    border-top: 1px solid $dark-card-border;
                  }
                }
              }
              table.dataTable.display,
              table.dataTable.order-column.hover {
                tbody {
                  tr.even {
                    &:hover {
                      > .sorting_1 {
                        background-color: $dark-even-hover-sorting;
                      }
                    }
                  }
                }
              }
              table.dataTable.hover,
              table.dataTable.display {
                tbody {
                  tr {
                    &:hover {
                      background-color: $dark-even-hover-sorting;
                    }
                  }
                }
              }
              table.dataTable.cell-border {
                th,
                td {
                  border-top: 1px solid $dark-card-border;
                  border-right: 1px solid $dark-card-border;
                  &:first-child {
                    border-left: 1px solid $dark-card-border;
                  }
                }
              }
              table.dataTable.order-column,
              table.dataTable.display {
                tbody {
                  tr {
                    > .sorting_1,
                    > .sorting_2,
                    > .sorting_3 {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            #example-style-3_wrapper {
              #example-style-3 {
                tfoot {
                  border-top: 2px solid $dark-card-border;
                }
              }
            }
            .page-link {
              border: 1px solid $dark-card-border;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-card-border;
              }
            }
            .page-link {
              color: $dark-all-font-color;
              background-color: $dark-card-background;
            }
            .page-item {
              &:hover {
                .page-link {
                  background-color: $dark-body-background;
                }
              }
            }
            .page-item.active {
              .page-link {
                background-color: $dark-body-background;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .border-tab.nav-tabs {
              border-bottom: 1px solid $dark-card-border;
              .nav-item {
                .nav-link {
                  color: $dark-all-font-color;
                }
              }
              .nav-link {
                &.active,
                &:focus,
                &:hover {
                  color: var(--theme-default);
                }
              }
            }
            .br-theme-bars-1to10,
            .br-theme-bars-movie,
            .br-theme-bars-pill,
            .br-theme-bars-reversed,
            .br-theme-bars-horizontal {
              .br-widget {
                a {
                  background-color: $dark-card-border;
                  &.br-active,
                  &.br-selected {
                    background-color: var(--theme-default);
                  }
                }
              }
            }
            .br-theme-bars-square {
              .br-widget {
                a {
                  border: 2px solid $dark-card-border;
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                  &.br-active,
                  &.br-selected {
                    border: 2px solid var(--theme-default);
                    color: var(--theme-default);
                  }
                }
              }
            }
            .br-theme-fontawesome-stars,
            .br-theme-fontawesome-stars-o {
              .br-widget {
                a {
                  &.br-selected,
                  &.br-active {
                    &:after {
                      color: var(--theme-default);
                    }
                  }
                }
              }
            }
            .scroll-demo {
              border: 1px solid $dark-card-border;
            }
            .search-form {
              input {
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-body-background;
            }
            .cd-timeline-block {
              &:nth-child(odd) {
                .cd-timeline-content {
                  &::before {
                    border-left-color: $dark-card-border;
                  }
                }
              }
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-body-background;
                  }
                }
              }
            }
            .breadcrumb {
              &.bg-white {
                background-color: $dark-card-background !important;
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          #viewhtml {
            .render {
              background-color: $dark-card-background;
              color: $dark-all-font-color;
              border-color: $dark-card-border;
            }
          }
          .wizard-4 {
            ul.anchor {
              li {
                a.disabled {
                  color: $dark-body-background;
                }
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-card-border;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .jsgrid-grid-header {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-border;
          }
          .jsgrid-header-row,
          .jsgrid-filter-row {
            > .jsgrid-header-cell,
            > .jsgrid-cell {
              background: $dark-card-background;
              border: 1px solid $dark-card-border;
              input {
                background-color: $dark-body-background;
                border-color: $dark-card-border;
                color: $dark-all-font-color;
                &:focus {
                  outline: none;
                }
              }
            }
            select {
              background-color: $dark-body-background;
              border-color: $dark-card-border;
              color: $dark-all-font-color;
            }
          }
          .jsgrid-row {
            > .jsgrid-cell {
              background-color: $dark-card-background;
            }
          }
          .jsgrid-alt-row {
            > .jsgrid-cell {
              background-color: $dark-body-background;
            }
          }
          .jsgrid-cell,
          .jsgrid-grid-body {
            border: 1px solid $dark-card-border;
          }
          .jsgrid-selected-row {
            > .jsgrid-cell {
              background-color: $dark-datatable-sorting;
            }
          }
          .jsgrid {
            .jsgrid-pager {
              [class*="jsgrid-pager"] {
                border: 1px solid $dark-card-border;
              }
              .jsgrid-pager-page {
                a {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .jsgrid-pager-current-page {
            color: $dark-all-font-color;
            font-weight: 700;
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .card {
            .sub-title {
              color: $dark-all-font-color;
            }
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: var(--theme-default);
                }
              }
            }
            .separator {
              border-bottom: 1px solid $dark-card-border;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: var(--theme-default) !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: var(--theme-default);
                  a {
                    color: var(--theme-default);
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 14px 0 $dark-body-background;
              .navs-icon {
                li {
                  p {
                    color: $white;
                  }
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: var(--theme-default) !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
              .card-body {
                border: 1px solid $dark-card-border;
                border-top: none;
              }
            }
          }
          .light-card {
            background-color: $dark-body-background;
          }
          .border {
            border: 1px solid $dark-card-border !important;
          }
          .border-1 {
            border: 1px solid $dark-card-border !important;
          }
          .border-2 {
            border: 2px solid $dark-card-border;
          }
          .grid-showcase {
            span {
              &.border-2 {
                border: 2px solid $dark-card-border !important;
              }
            }
          }
          .border-3 {
            border: 3px solid $dark-body-background;
          }
          .border-4 {
            border: 4px solid $dark-body-background !important;
          }
          .border-5 {
            border: 5px solid $dark-body-background !important;
          }
          .border-6 {
            border: 6px solid $dark-body-background !important;
          }
          .border-7 {
            border: 7px solid $dark-body-background !important;
          }
          .border-8 {
            border: 8px solid $dark-body-background !important;
          }
          .border-9 {
            border: 9px solid $dark-body-background !important;
          }
          .border-10 {
            border: 10px solid $dark-body-background !important;
          }
          $border-gradient: primary, secondary, success, danger, warning, info,
            light, dark;
          $helper-border-color: $primary-color, $secondary-color, $success-color,
            $danger-color, $warning-color, $info-color, $light-color,
            $dark-color;
          @each $var in $border-gradient {
            $i: index($border-gradient, $var);
            .b-#{$var} {
              border: 1px solid nth($helper-border-color, $i) !important;
              background-color: $dark-body-background;
            }
            .border-t-#{$var} {
              border: none !important;
              border-top: 1px solid nth($helper-border-color, $i) !important;
            }
            .b-t-#{$var} {
              border: 1px solid nth($helper-border-color, $i) !important;
              background-color: $dark-body-background;
            }
            .border-b-#{$var} {
              border: none !important;
              border-bottom: 1px solid nth($helper-border-color, $i) !important;
            }
            .b-b-#{$var} {
              border: 1px solid nth($helper-border-color, $i) !important;
              background-color: $dark-body-background;
            }
            .border-l-#{$var} {
              border: none !important;
              border-left: 1px solid nth($helper-border-color, $i) !important;
            }
            .b-l-#{$var} {
              border: 1px solid nth($helper-border-color, $i);
              background-color: $dark-body-background;
            }
            .border-r-#{$var} {
              border: none !important;
              border-right: 1px solid nth($helper-border-color, $i) !important;
            }
            .b-r-#{$var} {
              border: 1px solid nth($helper-border-color, $i) !important;
              background-color: $dark-body-background;
            }
            .border-#{$var} {
              border: 1px solid nth($helper-border-color, $i) !important;
            }
          }
        }
        .footer {
          p {
            color: $dark-all-font-color;
          }
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .custom-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-card-border;
        }
      }
      .note {
        textarea {
          color: $dark-all-font-color;
        }
      }
      .dt-button-info {
        background-color: $dark-card-background;
        border: 1px solid $dark-small-font-color;
        h2 {
          background-color: $dark-card-background;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-card-border;
          .nav-tabs {
            border-bottom: 1px solid $dark-card-border;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }
          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-card-border;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-card-border;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-card-border;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-card-border;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-card-border;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
      pre {
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-card-border;
      }
      .input-group-text {
        background-color: $dark-card-background;
        border: 1px solid $dark-card-border;
        color: rgba(255, 255, 255, 0.6);
        i {
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        + .list-group-item {
          border-top-width: 0;
        }
        &.active {
          background-color: var(--theme-default) !important;
          border-color: var(--theme-default);
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .dark-list {
        .list-group {
          .border-left-primary {
            border-left: 4px solid var(--theme-default);
          }
          .border-left-secondary {
            border-left: 4px solid var(--theme-secondary);
          }
          .border-left-warning {
            border-left: 4px solid $warning-color;
          }
          .border-left-success {
            border-left: 4px solid $success-color;
          }
          .border-left-info {
            border-left: 4px solid $info-color;
          }
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-card-border;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-card-border;
          .close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-card-border;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-card-border;
            border-bottom: 1px solid $dark-card-border;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-card-border;
          &:before {
            background: $dark-card-border;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-card-border;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-card-border;
                }
              }
              .opt-box {
                background-color: $dark-card-border;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-card-border;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-card-border;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .vertical-menu-main {
        background-color: $dark-card-background;
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: var(--theme-default);
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-border;
            border-bottom: none;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: var(--theme-default);
            border: 1px solid var(--theme-default);
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: var(--theme-secondary);
            border: 1px solid var(--theme-secondary);
          }
        }
      }
      // .collapse {
      // 	.card-body {
      // 		background-color: $dark-card-background;
      // 	}
      // }
      .balance-widget.card-body {
        background-color: $dark-card-background;
        background-blend-mode: overlay;
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-card-border;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .mobile-title {
          &.d-none {
            border-bottom: 1px solid $dark-card-border;
          }
        }
        .chat-menu {
          border-top: 1px solid $dark-card-border;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 575.98px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-card-border;
                border-bottom: 1px solid $dark-card-border;
              }
            }
          }
        }
        .page-wrapper {
          &.compact-wrapper,
          &.compact-sidebar {
            .page-header {
              .header-wrapper {
                .toggle-sidebar {
                  border-right: 1px solid $dark-card-border;
                }
              }
            }
          }
        }
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .drag {
      background-color: $dark-card-background;
      form {
        background: $dark-card-background;
        box-shadow: 0 0 30px $dark-card-border;
        border: 1px dotted $dark-card-border;
        input {
          background-color: $dark-body-background;
          border: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }
        .help-block {
          text-transform: capitalize;
          color: $dark-small-font-color;
        }
      }
    }
    .draggable {
      input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-card-border;
      }
      p {
        color: $dark-small-font-color;
      }
      select {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border: 1px solid $dark-card-border;
      }
      .radio,
      .checkbox {
        label {
          &::before {
            background-color: $dark-body-background;
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    .search-page {
      .info-block {
        border: 1px solid $dark-card-border;
        a {
          color: $white;
        }
      }
    }
    .social-status {
      .d-flex {
        .flex-grow-1 {
          span {
            &:first-child {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .filter-cards-view {
      span {
        color: $dark-all-font-color;
      }
    }
    .icofont-ui-edit {
      color: $dark-all-font-color;
    }
    .select2-container--default {
      .select2-selection--multiple,
      .select2-selection--single {
        background-color: $dark-body-background;
        border: 1px solid $dark-body-background !important;
      }
      .select2-search--inline {
        .select2-search__field {
          color: $dark-all-font-color;
        }
      }
      .select2-selection--single {
        .select2-selection__rendered {
          color: $dark-all-font-color;
        }
      }
      .select2-search--dropdown {
        .select2-search__field {
          background-color: $dark-body-background;
          border: 1px solid $dark-card-border;
          color: $dark-all-font-color;
        }
      }
      .select2-results__option--highlighted[aria-selected] {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color !important;
      }
    }
    .select2-dropdown {
      background-color: $dark-body-background;
      border: 1px solid $dark-card-border;
    }
    .select2-drpdwn {
      .form-control-primary {
        border-color: var(--theme-default) !important;
        color: var(--theme-default) !important;
      }
      .form-control-secondary {
        border-color: var(--theme-secondary) !important;
        color: var(--theme-secondary) !important;
      }
      .form-control-success {
        border-color: $success-color !important;
        color: $success-color !important;
      }
      .form-control-info {
        border-color: $info-color !important;
        color: $info-color !important;
      }
      .form-control-warning {
        border-color: $warning-color !important;
        color: $warning-color !important;
      }
      .form-control-danger {
        border-color: $danger-color !important;
        color: $danger-color !important;
      }
      .form-control-inverse {
        border-color: $white !important;
        color: $white !important;
      }
      .form-control-primary-fill {
        background-color: var(--theme-default) !important;
        color: $white !important;
      }
      .form-control-secondary-fill {
        background-color: var(--theme-secondary) !important;
        color: $white !important;
      }
      .form-control-success-fill {
        background-color: $success-color !important;
        color: $white !important;
      }
      .form-control-info-fill {
        background-color: $info-color !important;
        color: $white !important;
      }
      .form-control-warning-fill {
        background-color: $warning-color !important;
        color: $white !important;
      }
      .form-control-danger-fill {
        background-color: $danger-color !important;
        color: $white !important;
      }
      .form-control-inverse-fill {
        background-color: $white !important;
        color: $black !important;
      }
    }
    .select2-container--default.select2-container--disabled {
      .select2-selection--single {
        background-color: $dark-card-border;
      }
    }
    .theme-form {
      input[type="text"],
      input[type="email"],
      input[type="search"],
      input[type="password"],
      input[type="number"],
      input[type="tel"],
      input[type="date"],
      input[type="datetime-local"],
      input[type="time"],
      input[type="datetime-local"],
      input[type="month"],
      input[type="week"],
      input[type="url"],
      input[type="file"],
      textarea,
      select {
        border: 1px solid $dark-card-border;
        background-color: $dark-body-background !important;
        color: $dark-all-font-color !important;
      }
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border: 1px solid $dark-card-border;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-card-border;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-card-border;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-card-border;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }

    .editor-toolbar {
      border-top: 1px solid $dark-card-border;
      border-left: 1px solid $dark-card-border;
      border-right: 1px solid $dark-card-border;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-card-border;
        }
      }
      i.separator {
        border-left: 1px solid $dark-card-border;
        border-right: 1px solid $dark-card-border;
      }
      &.fullscreen {
        &::before {
          background: linear-gradient(
            to right,
            $dark-body-background 0,
            rgba(0, 0, 0, 0) 100%
          );
        }
        &::after {
          background: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0,
            $dark-body-background 100%
          );
        }
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-card-border;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-card-border;
    }
    .u-step {
      background: $dark-card-border;
      &.active,
      &.current {
        background: var(--theme-default);
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-card-border;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-card-border;
        border: 2px solid $dark-card-border;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .modal-body {
      .form-bookmark {
        label {
          color: $dark-all-font-color;
        }
        .form-control {
          background-color: $dark-body-background !important;
          border: none;
          color: $dark-all-font-color;
          &::placeholder {
            color: $dark-all-font-color;
          }
        }
      }
      h4 {
        color: $dark-all-font-color;
      }
    }
    .modal-footer {
      border-top: 1px solid $dark-card-border;
    }
    .feature-products {
      form {
        .form-group {
          input {
            background-color: $dark-card-background;
          }
        }
      }
    }

    .note-editor.note-frame {
      border-color: $dark-card-border;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-card-border;
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border $dark-card-border
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-card-border $dark-card-border $dark-card-inbox;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-card-border $dark-card-border
            $dark-card-background;
        }
      }
    }
    .nav-primary {
      .nav-link {
        &.active {
          background-color: var(--theme-default);
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: var(--theme-default);
        }
      }
    }
    .custom-tab {
      &.nav-tabs {
        border-bottom: none;
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-card-border
              $dark-card-border;
          }
        }
      }
    }
    .border-tab {
      .nav-link {
        &.active {
          background-color: transparent;
        }
      }
      .show {
        > .nav-link {
          background-color: transparent;
        }
      }
    }
    .border-tab.nav-left,
    .border-tab.nav-right {
      .nav-link {
        color: $dark-all-font-color;
        &.active {
          color: var(--theme-default);
        }
      }
      .show {
        > .nav-link {
          color: var(--theme-default);
        }
      }
    }
    .border-tab.nav-left.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          border-left-color: var(--theme-secondary);
          color: var(--theme-secondary) !important;
        }
      }
      .show > .nav-link {
        border-left-color: var(--theme-secondary);
        color: var(--theme-secondary) !important;
      }
      .nav-item.show {
        color: var(--theme-secondary) !important;
        border-left-color: var(--theme-secondary);
      }
    }
    .border-tab.nav-left.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $success-color;
        color: $success-color !important;
      }
      .nav-item.show {
        color: $success-color !important;
        border-left-color: $success-color;
      }
    }
    .border-tab.nav-right.nav-info {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $info-color !important;
        }
      }
      .show > .nav-link {
        border-left-color: $info-color;
        color: $info-color !important;
      }
      .nav-item.show {
        color: $info-color !important;
        border-left-color: $info-color;
      }
    }
    .border-tab.nav-secondary {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: var(--theme-secondary) !important;
        }
      }
      .nav-item {
        &.show {
          color: var(--theme-secondary) !important;
        }
      }
    }
    .border-tab.nav-success {
      .nav-link {
        &.active,
        &:focus,
        &:hover {
          color: $success-color !important;
        }
      }
      .nav-item {
        &.show {
          color: $success-color !important;
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-card-border;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-card-border;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-card-border;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: $dark-body-background;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-card-border;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-card-border;
      border-bottom: 1px solid $dark-card-border;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
              &::after {
                background-color: $dark-card-border;
                color: $dark-all-font-color;
                border: none;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-card-border;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            &:last-child {
              .task-container {
                border: none;
              }
            }
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-card-border;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-card-border;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: var(--theme-default);
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-card-border;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .introjs-helperLayer {
      background-color: rgba(54, 64, 74, 0.9);
      border: rgba(255, 255, 255, 0.5);
    }
    .jstree-default {
      .jstree-node,
      .jstree-icon {
        color: #455869 !important;
      }
      .jstree-anchor {
        color: $dark-small-font-color;
      }
      .jstree-clicked {
        color: $white;
        background-color: $transparent-color;
      }
      .jstree-hovered {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
        border-top: 1px solid $dark-card-border;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-card-border;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-card-border;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .note {
      textarea {
        color: $dark-all-font-color;
      }
    }
    .dt-button-info {
      background-color: $dark-card-background;
      border: 1px solid $dark-card-border;
      h2 {
        background-color: $dark-card-background;
        border-bottom: 1px solid $dark-card-border;
      }
    }
    pre {
      background-color: $dark-body-background;
    }
    #keytable_wrapper,
    #column-selector_wrapper,
    #auto-fill_wrapper,
    #custom-button_wrapper,
    #class-button_wrapper,
    #keyboard-btn_wrapper,
    #multilevel-btn_wrapper,
    #pagelength-btn_wrapper,
    #basic-colreorder_wrapper,
    #state-saving_wrapper,
    #real-time_wrapper,
    #basic-fixed-header_wrapper,
    #fixed-header-footer_wrapper,
    #export-button_wrapper,
    #excel-cust-bolder_wrapper,
    #cust-json_wrapper,
    #basic-key-table_wrapper,
    #focus-cell_wrapper,
    #responsive_wrapper,
    #new-cons_wrapper,
    #show-hidden-row_wrapper,
    #basic-row-reorder_wrapper,
    #full-row_wrapper,
    #rest-column_wrapper {
      .dataTables_paginate {
        border: none;
      }
    }
    #example-style-8_wrapper {
      table.dataTable.stripe,
      table.dataTable.display {
        tbody {
          tr.odd {
            background-color: $dark-card-border;
          }
        }
      }
    }
    .error-wrapper {
      background-color: rgba(54, 64, 74, 0.8);
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-card-border !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-card-border;
        .close {
          color: $dark-all-font-color;
          font-weight: 400;
        }
      }
      .ui-front {
        .form-control {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-card-border;
        }
      }
    }
    .modal {
      .theme-close {
        color: $dark-all-font-color;
      }
    }
    .token.atrule,
    .token.attr-value,
    .token.keyword {
      color: $info-color;
    }
    .token {
      &.boolean,
      &.constant,
      &.deleted,
      &.number,
      &.property,
      &.symbol,
      &.tag {
        color: $danger-color;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-card-border !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border-left: 5px solid var(--theme-default);
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-card-border;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .order-history {
      table {
        .qty-box {
          button {
            background-color: $dark-body-background !important;
            border: none !important;
          }
          .input-group {
            border-color: $dark-card-border;
          }
        }
        thead {
          tr {
            th {
              background-color: $dark-body-background;
            }
          }
        }
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          &.list-group-item {
            border: none;
          }
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        > a {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, var(--theme-default)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, var(--theme-default)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, var(--theme-default)),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-card-border;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    #cal-basic {
      .fc-toolbar {
        .fc-left,
        .fc-right {
          .fc-next-button {
            .fc-icon-right-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
          .fc-prev-button {
            .fc-icon-left-single-arrow {
              &:after {
                color: $dark-small-font-color;
              }
            }
          }
        }
        .fc-left {
          .fc-button-group {
            .fc-month-button {
              color: $white !important;
            }
          }
        }
      }
    }
    .fc-button-group {
      .fc-basicWeek-button,
      .fc-basicDay-button,
      .fc-agendaWeek-button,
      .fc-agendaDay-button {
        color: $black !important;
      }
      .fc-month-button {
        color: $white !important;
      }
    }
    .btn-light {
      &:not([disabled]):not(.disabled).active.active {
        background-color: $dark-body-background !important;
      }
      &.disabled,
      &:disabled {
        color: rgba($theme-body-font-color, 0.5) !important;
      }
    }
    #cal-agenda-view {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-month-button,
            .btn-light {
              color: $black !important;
            }
            .fc-agendaWeek-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .basic-calendar,
    #cal-bg-events,
    #cal-event-colors {
      .fc-toolbar {
        .fc-right {
          .fc-button-group {
            .fc-basicWeek-button,
            .btn-light {
              color: $black !important;
            }
            .fc-month-button {
              color: $white !important;
              &:hover {
                color: $black !important;
              }
            }
          }
        }
      }
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .knowledgebase-search {
      .form-inline {
        .form-control {
          background-color: unset;
          color: $dark-body-background;
          &::placeholder {
            color: rgba($dark-body-background, 0.3);
          }
        }
      }
    }
    .social-status {
      .d-flex {
        .flex-grow-1 {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .flex-grow-1 {
        border: 1px solid $dark-card-border;
        &:after {
          border-right: 7px solid $dark-card-background;
          [dir="rtl"] & {
            border-left: 7px solid $dark-card-background;
          }
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    //index page
    @media only screen and (max-width: 991.98px) {
      .page-wrapper.compact-wrapper
        .page-header
        .header-wrapper
        .toggle-sidebar {
        background-color: $dark-body-background;
      }
    }
    @media only screen and (max-width: 767.98px) {
      .page-wrapper {
        &.material-icon {
          .page-header {
            background-color: $dark-card-background;
          }
        }
      }
    }
    .apexcharts-tooltip.light {
      border-color: $dark-card-border;
      background-color: $dark-body-background;
      .apexcharts-tooltip-title {
        border-color: $dark-card-border;
      }
      .apexcharts-tooltip-text-label,
      .apexcharts-tooltip-text-value {
        color: $white;
      }
    }
    // Alert dark mode
    .dark-alert {
      .alert {
        p {
          color: $dark-all-font-color;
        }
      }
    }
    .gradient-border {
      .helper-common-box {
        .fill-wrapper {
          background-color: unset;
        }
      }
    }
    .dark-txt {
      p {
        strong {
          color: $dark-all-font-color !important;
        }
      }
    }
    .live-dark {
      .btn-close {
        filter: initial;
      }
    }
    .alert-light {
      color: $dark-body-background !important;
    }
    // dropdown dark mode
    .dark-input-type {
      .input-group {
        .input-group-text {
          background: transparent;
          border: unset;
        }
      }
    }
    .heading-dropdown {
      .dropdown-menu {
        > li {
          &:first-child {
            > a {
              background-color: $card-body-color;
            }
          }
        }
      }
    }
    .heading-dropdown {
      .dropdown-menu {
        a {
          &.dropdown-item {
            color: $dark-card-border;
          }
        }
      }
    }
    // Accordion dark mode
    .dark-accordion {
      .accordion-item {
        .accordion-collapse {
          p {
            color: $dark-body-background;
          }
          .accordion-body {
            background-color: $dark-card-background;
            h6 {
              border: 0;
            }
          }
        }
        &:last-of-type {
          .accordion-button.collapsed {
            background-color: $dark-card-background;
          }
        }
        border: 1px solid $dark-card-border;
        background-color: $dark-card-border;
      }
      .collapse-wrapper {
        color: $dark-body-background;
      }
      &.card {
        border-radius: unset;
      }
    }
    .accordions-content {
      li {
        color: $dark-all-font-color;
      }
    }
    // List dark mode
    .dark-list {
      .list-group {
        .list-group-item {
          + .list-group-item {
            border-top-width: 1px;
          }
          .form-check-input {
            border: 1px solid $dark-all-font-color;
            background-color: transparent;
          }
        }
      }
      .list-light-dark {
        background-color: $dark-body-background;
      }
    }
    // Typography dark mode
    .card-wrapper {
      .sub-title {
        border-bottom: unset !important;
      }
      .payment-wrapper {
        .payment-second {
          .mega-icons {
            stroke: $white;
          }
        }
      }
    }

    .dark-blockquote {
      .blockquote {
        p {
          color: $dark-all-font-color !important;
        }
      }
    }
    // Modal dark mode
    .dark-sign-up {
      h3 {
        color: $dark-card-background;
      }
      p {
        color: $dark-card-background;
      }
      .modal-body {
        form {
          .form-label {
            color: $bg-dark-color;
          }
          .form-check {
            .form-check-label {
              color: $bg-dark-color;
            }
          }
        }
      }
    }
    .modal-dialog {
      .modal-content {
        .dark-modal {
          .large-modal-header {
            svg {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-all-font-color;
            }
          }
          .large-modal-body {
            svg {
              color: $dark-all-font-color;
            }
            p {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .modal-toggle-wrapper {
      .dark-toggle-btn {
        color: $dark-all-font-color;
      }
    }
    .social-profile {
      background-attachment: fixed;
      .social-details {
        .card-social,
        .social-follow {
          li {
            background-color: unset !important;
          }
        }
        h5 {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    // helper-classes
    .dark-helper {
      background-color: rgba($dark-body-background, 0.4);
      h6,
      span {
        color: $dark-all-font-color;
      }
      .text-muted {
        color: $dark-card-background !important;
      }
    }
    .font-dark {
      color: $dark-all-font-color !important;
    }
    .grid-showcase {
      span {
        border: 1px solid $dark-card-border;
      }
    }
    .progress {
      background-color: $dark-body-background !important;
    }
    // treeview
    .treejs {
      .treejs-node__disabled {
        color: $dark-all-font-color;
      }
      .treejs-switcher:before {
        border-top: 4px solid $dark-all-font-color;
      }
    }
    // range-slider
    .irs-min,
    .irs-max {
      color: $dark-small-font-color;
      background-color: $dark-card-inbox;
    }
    // Ribbons
    .ribbon-wrapper,
    .ribbon-wrapper-bottom,
    .ribbon-vertical-left-wrapper,
    .ribbon-wrapper-right,
    .ribbon-vertical-right-wrapper {
      &.alert-light-light {
        background-color: $dark-body-background;
      }
    }
    .quick-file {
      li {
        .quick-box {
          border: 1px solid $dark-card-border;
        }
      }
    }
    .files-content {
      li {
        .files-list {
          .file-left {
            border: 1px solid $dark-card-border;
          }
        }
      }
    }
    // Paginations
    .pagination {
      .page-item {
        .page-link {
          &.rounded-circle {
            color: $dark-all-font-color !important;
          }
        }
      }
    }
    // Timeline
    .dark-timeline {
      .date-content {
        color: $dark-all-font-color !important;
        background-color: $dark-body-background !important;
      }
    }
    // dashboard-7
    .student-leader-wrapper {
      .student-leader-content {
        border-bottom: 1px solid $dark-card-border;
      }
    }
    .attendance-card {
      .left-overview-content {
        .svg-box {
          box-shadow: unset;
          border: 1px solid $dark-card-border;
        }
      }
    }
    .task-table {
      .main-task-wrapper {
        .square-white {
          box-shadow: unset;
          border: 1px solid transparent;
        }
      }
    }
    .income-wrapper {
      ul {
        li {
          + li {
            border-left: 1px dashed $dark-card-border;
            @media (max-width: 1735px) {
              border-left: unset;
            }
          }
        }
      }
    }
    .icons-box-2 {
      .open-options {
        .dropdown-menu {
          background-color: $dark-body-background;
        }
      }
    }
    .performance-wrapper {
      .performance-right {
        p {
          color: var(--body-font-color);
        }
      }
    }
    // dashboard-8
    .main-product-wrapper {
      .product-body {
        .product-search {
          &.input-group {
            > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
                .valid-feedback
              ):not(.invalid-tooltip):not(.invalid-feedback) {
              color: $dark-small-font-color;
            }
            .input-group-text {
              border: 1px solid $dark-card-border;
            }
            .form-control {
              border: 1px solid $dark-card-border;
              border-left: unset;
              background-color: $dark-card-background;
              [dir="rtl"] & {
                border: 1px solid $dark-card-border;
                border-right: unset;
              }
            }
          }
        }
      }
    }
    .our-product-wrapper {
      box-shadow: 0px 3px 17px rgb(113 113 137 / 18%);
    }
    // grid
    .grid-showcase {
      span {
        background-color: $dark-body-background;
      }
    }
    // forms
    .custom-input #validationCustom03.form-control,
    .custom-input #validationCustom03 .form-select,
    .custom-input #validationCustom05.form-control,
    .custom-input #validationCustom05 .form-select,
    .custom-input #validationCustomUsername.form-control,
    .custom-input #validationCustomUsername .form-select {
      border-color: $dark-body-background;
    }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      color: $dark-all-font-color;
      background-color: $dark-body-background;
      border-color: $dark-body-background;
    }
    .custom-input {
      .form-control,
      .form-select {
        background-color: $dark-body-background;
        border-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .vertical-main-wizard {
      .header-vertical-wizard {
        .nav-link {
          &:active,
          &.active {
            .vertical-wizard {
              .vertical-wizard-content {
                h6 {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
      }
    }
    .selected-box {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
      .options-container {
        background-color: $dark-body-background;
      }
    }
    .tagify__dropdown__wrapper {
      background-color: $dark-body-background;
    }
    .tagify {
      &.customLook {
        .tagify__tag-text {
          color: $dark-all-font-color;
        }
      }
    }
    .custom-radio-buttons {
      label {
        color: $dark-body-background;
      }
    }
    .react-calendar {
      border-color: $dark-body-background;
      background-color: $dark-body-background;
      .react-calendar__viewContainer {
        border: unset;
      }
    }
    .react-calendar {
      .react-calendar__tile {
        color: $white;
        background-color: $dark-body-background;
        &:hover {
          background-color: $dark-body-background;
        }
      }
    }
    .needs-validation {
      .dark-accordion {
        .accordion-item {
          .accordion-header {
            button {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .react-datepicker-popper[data-placement^="bottom"] {
      .react-datepicker__triangle {
        fill: $dark-card-border;
        color: $dark-card-border;
        stroke: $dark-card-border;
      }
    }
    .react-datepicker-popper[data-placement^="top"] {
      .react-datepicker__triangle {
        fill: $dark-card-border;
        color: $dark-card-border;
        stroke: $dark-card-border;
      }
    }
    .react-datepicker {
      border-color: $dark-card-border;
      background-color: unset;
      .react-datepicker__time-container {
        border-left-color: $dark-card-border;
      }
      .react-datepicker__header {
        border-bottom: $dark-card-border;
        background-color: $dark-body-background;
      }
      .react-datepicker-time__header {
        color: $white;
      }
      .react-datepicker__time-list-item {
        background-color: $dark-body-background;
        &:hover {
          background-color: $dark-card-background;
        }
      }
      .react-datepicker__time {
        color: $white;
        background-color: $dark-body-background;
      }
      .react-datepicker__month {
        background-color: $dark-body-background;
      }
      .react-datepicker__month-container {
        background-color: $dark-body-background;
        border-color: $dark-body-background;
      }
    }

    .react-datepicker__day-name {
      color: $dark-all-font-color;
    }
    .react-datepicker__day {
      color: $dark-all-font-color;
      &:hover {
        background-color: $dark-card-background;
        color: $dark-all-font-color;
      }
    }
    .react-datepicker {
      color: $dark-all-font-color;
    }
    .react-datepicker__time-name {
      color: $dark-all-font-color;
    }
    .react-calendar__tile--active {
      &:enabled {
        &:hover {
          background: $primary-color;
        }
        &:focus {
          background: $primary-color;
        }
      }
    }
    .new-users-social {
      .input-txt-bx {
        background-color: $dark-card-background;
      }
    }
    .comments-box {
      .input-txt-bx {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        .input-group-append {
          border-top-right-radius: 0px !important;
          border-bottom-right-radius: 0px !important;
        }
      }
    }
    .react-calendar__navigation {
      button {
        color: rgba($white, 0.4);
        &:enabled {
          &:hover {
            background-color: $dark-card-background;
          }
          &:focus {
            background-color: $dark-card-background;
          }
        }
      }
    }

    caption {
      color: $dark-all-font-color;
    }
    // Advance Init table
    td.highlight {
      background-color: $dark-datatable-odd !important;
    }
    // Base-input
    .card-wrapper,
    .dark-field {
      .form-control {
        // background-color: $dark-body-background !important;
        // border-color: $dark-body-background !important;
        color: $dark-all-font-color;
      }
    }
    .form-control-sm {
      background-color: $dark-body-background;
      border-color: $dark-body-background;
    }
    .dark-inputs {
      .form-control {
        &.btn-square {
          background-color: $dark-body-background;
          border-color: $dark-body-background;
        }
        &.btn-pill {
          background-color: $dark-body-background;
          border-color: $dark-body-background;
        }
        &.input-air-primary {
          background-color: $dark-body-background;
          border-color: $dark-body-background;
        }
      }
    }
    .horizontal-wizard-wrapper {
      .main-horizontal-header {
        .horizontal-options {
          .nav-link {
            .horizontal-wizard {
              .stroke-icon-wizard {
                color: $dark-body-background;
              }
            }
          }
        }
      }
    }
    // Touchspin
    .touchspin-wrapper,
    .pre-post-touchspin {
      input[type="number"] {
        &.input-touchspin {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
      }
    }
    // datepicker
    .flatpickr-calendar {
      box-shadow: unset;
      &.animate {
        background-color: $dark-body-background;
      }
    }
    .flatpickr-time {
      border: 1px solid $dark-card-inbox;
      input {
        background: $dark-datatable-sorting-even;
      }
    }

    .main-inline-calender {
      &.input-group {
        .flatpickr-rContainer {
          .flatpickr-days {
            .dayContainer {
              .flatpickr-day {
                &:hover {
                  background-color: $dark-card-background;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
    }
    // Select2
    .tagify__dropdown__item--active {
      background-color: $dark-body-background;
    }
    .tagify__input {
      color: $dark-small-font-color;
      &::before {
        color: $dark-small-font-color;
      }
    }
    .tagify {
      border-color: $dark-card-border;
      --tags-focus-border-color: #374558;
    }
    .international-num {
      input {
        border-color: $dark-card-border;
        background-color: $dark-card-background;
      }
      .results {
        ul {
          border: 1px solid $dark-card-border;
        }
      }
    }
    .iti__country-list {
      background-color: $dark-card-background;
    }
    .select-box {
      .selection-option {
        &:hover {
          background-color: $dark-datatable-sorting-even;
        }
      }
      .options-container.active {
        ~ {
          .search-box {
            input {
              background-color: $dark-all-font-color;
            }
          }
        }
      }
    }
    // Tables
    .table-hover {
      > tbody {
        > tr:hover {
          --bs-table-accent-bg: $dark-card-background;
        }
      }
    }
    .filepond--drop-label {
      background-color: $dark-body-background;
    }
    .animate-widget {
      .p-25 {
        background-color: $dark-body-background;
      }
    }
    // Authentication
    .main-qr-code {
      .modal-toggle-wrapper {
        .modal-img {
          .qr-content {
            .alert {
              background-color: $dark-body-background;
              border-color: $dark-card-inbox;
              i {
                color: $dark-all-font-color;
              }
              div {
                color: $dark-all-font-color;
              }
            }
          }
        }
        form {
          .form-control {
            background-color: $dark-body-background;
            border-color: $dark-card-inbox;
          }
        }
      }
    }
    .authentication-options {
      .radio-wrapper {
        li {
          .form-check-label {
            span {
              > span {
                color: $dark-small-font-color;
              }
            }
          }
        }
      }
    }
    .react-datepicker__time-container {
      .react-datepicker__time {
        .react-datepicker__time-box {
          ul.react-datepicker__time-list {
            li.react-datepicker__time-list-item {
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }

    // Typeahead
    .typeahead-wrapper {
      .theme-form {
        .form-control {
          background-color: $dark-body-background !important;
          border-color: $dark-body-background !important;
        }
      }
    }
    // Letter_box
    .compose-modal {
      form {
        .form-control {
          background-color: $dark-body-background;
          border-color: $dark-card-border;
          color: $dark-small-font-color;
        }
      }
    }
    .toolbar-box {
      .ql-snow {
        .ql-stroke {
          stroke: $dark-small-font-color;
        }
        .ql-tooltip {
          background-color: $dark-body-background;
          box-shadow: unset;
          border: 0;
          &.ql-editing {
            input[type="text"] {
              background-color: $dark-card-background;
              border: 0;
              color: $dark-small-font-color;
            }
          }
        }
        a {
          color: $dark-all-font-color;
        }
      }
      .ql-editor.ql-blank::before {
        color: $dark-all-font-color;
      }
    }
    .email-body {
      .mail-header-wrapper {
        .mail-body {
          .mail-search {
            .form-control {
              background-color: $dark-body-background !important;
              border: 0;
              color: $dark-all-font-color;
            }
            i {
              color: $dark-all-font-color;
            }
          }
          .dropdown-menu {
            .dropdown-item {
              background: $dark-body-background;
              opacity: 1;
              border: 0;
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .inbox-options {
      .light-square {
        &.btn-group {
          .dropdown-menu {
            background: $dark-editor-document;
            .dropdown-item {
              &:hover {
                background-color: $dark-all-font-color;
                color: $dark-small-font-color;
              }
            }
          }
        }
      }
    }
    .inbox-security {
      background-color: $dark-body-background;
    }
    .mail-pagination {
      .pagination-number,
      .pagination-button {
        background-color: $dark-card-inbox;
      }
    }
    // Add-product
    .flatpicker-calender {
      &.product-date {
        .form-control {
          &.flatpickr-input {
            border: unset;
            background-color: $dark-body-background;
          }
        }
      }
    }
    .price-wrapper {
      .radio-wrapper {
        li {
          .form-check-input {
            border: 1px solid $dark-card-border;
            ~ .form-check-label {
              span {
                color: $dark-small-font-color;
              }
            }
          }
        }
      }
    }
    .sidebar-left-wrapper {
      .sidebar-left-icons {
        border-bottom: none;
      }
    }
    .product-buttons {
      .btn {
        &:hover,
        &:active {
          color: $dark-all-font-color;
        }
        svg {
          fill: $white;
          stroke: $dark-all-font-color;
        }
      }
    }

    // list-product
    .list-product {
      .datatable-pagination {
        .datatable-active {
          .datatable-pagination-list-item-link {
            background-color: var(--theme-default);
            color: var(--white);
          }
        }
        .datatable-pagination-list-item-link {
          background-color: $dark-body-background;
          color: $dark-small-font-color;
        }
      }
      .datatable-input {
        &:focus {
          outline: unset;
        }
      }
      .datatable-selector {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
    }
    .list-product-header {
      .collapse {
        .list-product-body {
          border: unset !important;
        }
      }
    }
  }
  &.dark-sidebar {
    .toggle-sidebar {
      svg {
        stroke: var(--theme-default);
      }
    }
    .page-wrapper {
      .sidebar-main-title {
        p {
          color: $dark-all-font-color;
        }
      }
      &.horizontal-wrapper {
        .logo-wrapper {
          .for-light {
            display: block;
          }
          .for-dark {
            display: none;
          }
        }
      }
      &.compact-wrapper {
        .page-body-wrapper {
          div.sidebar-wrapper {
            background-color: $dark-card-background;
            .sidebar-main {
              .sidebar-links {
                li {
                  a {
                    span {
                      color: $dark-all-font-color;
                    }
                    svg {
                      stroke: $dark-all-font-color;
                    }
                  }
                }
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        > li {
                          .mega-menu-container {
                            .mega-box {
                              .link-section {
                                .submenu-title {
                                  h5 {
                                    color: $dark-all-font-color;
                                  }
                                }
                                .submenu-content {
                                  &.opensubmegamenu {
                                    li {
                                      a {
                                        color: $dark-all-font-color;
                                        font-weight: 400;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                            &::after {
                              background-color: $dark-all-font-color;
                            }
                          }
                          .sidebar-submenu {
                            li {
                              a {
                                color: $dark-all-font-color;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                .sidebar-list {
                  ul.sidebar-submenu {
                    li {
                      a {
                        span {
                          color: $dark-all-font-color;
                        }
                        &.active {
                          color: var(--theme-default);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
      &.horizontal-wrapper {
        .page-body-wrapper {
          .sidebar-wrapper {
            background: $dark-card-background;
            .sidebar-main {
              background-color: $dark-card-background;
              .sidebar-links {
                .simplebar-wrapper {
                  .simplebar-mask {
                    .simplebar-content-wrapper {
                      .simplebar-content {
                        .menu-box {
                          ul {
                            li {
                              &.sidebar-list {
                                a {
                                  color: $sidebar-fill-icon-color;
                                }
                              }
                            }
                          }
                        }
                        > li {
                          > a {
                            color: $dark-all-font-color;
                          }
                          .sidebar-submenu {
                            background: $dark-card-background;
                            li {
                              a {
                                color: $dark-all-font-color;
                                &.active,
                                &:hover {
                                  color: var(--theme-default);
                                }
                              }
                              .opensubmegamenu {
                                background: $dark-card-background;
                              }
                            }
                          }
                          .mega-menu-container {
                            background: $dark-card-background;
                            .mega-box {
                              .link-section {
                                .submenu-content {
                                  li {
                                    a {
                                      color: $dark-all-font-color;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .left-arrow,
              .right-arrow {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
      &.compact-sidebar {
        .page-body-wrapper {
          div {
            &.sidebar-wrapper {
              > div {
                background: $dark-card-background;
              }
              .sidebar-main {
                .sidebar-links {
                  li {
                    .sidebar-title {
                      border-bottom: 1px solid $dark-card-border;
                    }
                    a {
                      span {
                        color: $dark-all-font-color;
                      }
                      svg {
                        stroke: $dark-all-font-color;
                      }
                    }
                    .sidebar-submenu,
                    .mega-menu-container {
                      background-color: $dark-card-background;
                      li {
                        a {
                          border-bottom: 1px solid $dark-card-border;
                        }
                      }
                    }
                  }
                  .simplebar-wrapper {
                    .simplebar-mask {
                      .simplebar-content-wrapper {
                        .simplebar-content {
                          > li {
                            .sidebar-link {
                              &.active {
                                background-color: rgba($primary-color, 0.2);
                                span {
                                  color: var(--theme-default);
                                }
                                svg {
                                  color: var(--theme-default);
                                  stroke: var(--theme-default);
                                }
                              }
                            }
                            .mega-menu-container {
                              .mega-box {
                                .link-section {
                                  .submenu-title {
                                    h5 {
                                      color: $dark-all-font-color;
                                    }
                                  }
                                  .submenu-content {
                                    &.opensubmegamenu {
                                      li {
                                        a {
                                          color: $dark-all-font-color;
                                          font-weight: 400;
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              &::after {
                                background-color: $dark-all-font-color;
                              }
                            }
                            .sidebar-submenu {
                              li {
                                a {
                                  color: $dark-all-font-color;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .sidebar-list {
                    ul.sidebar-submenu {
                      li {
                        a {
                          span {
                            color: $dark-all-font-color;
                          }
                          &.active {
                            color: var(--theme-default);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .according-menu {
            i {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
  }
  .for-dark {
    display: none;
  }
  &[class*="dark-"] {
    .for-dark {
      display: block;
    }
    .for-light {
      display: none;
    }
  }
}
// =======================  Dark Body only  ======================= //
/**=====================
	4.1. Dark CSS ends
==========================**/
