.leave-content {
  span {
    &::before {
      content: "";
      position: absolute;
      left: -4px;
      top: -4px;
      height: 68px;
      width: 68px;
      border-radius: 50px;
      border-width: 1.5px;
      border-style: dashed;
      border-color: #5c61f2;
    }
  }
}

.apply_leave_btn_container {
  display: flex;
  justify-content: end;
  @media screen and (min-width: 768px) {
    display: block;
    width: 100%;
    position: absolute;
    left: 84% !important;
    top: 8.5% !important;
    z-index: 9;
  }
}

// #basic-12_filter {
//   position: relative;
//   left: 15px;
//   @media screen and (min-width: 768px) {
//     left: -150px;
//   }
// }

.leave_badge {
  border: var(--bs-border-width) solid var(--bs-border-color);
  background-color: #fff;
  font-size: 14px;
  color: var(--bs-body-color) !important;
  padding: 20px 10px;
  border-radius: 0.375rem;
  box-shadow: none;
}

.leave-toggle-switch {
  position: relative;
  display: inline-block;
  width: 150px; // Adjust width based on text length
  height: 45px;
  background-color: #ddd;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.4s;

  &::after {
    content: "Full Day";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 14px;
    transition: opacity 0.4s;
  }

  &::before {
    content: "Custom";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 14px;
    opacity: 0.5;
    transition: opacity 0.4s;
  }
}

.leave-toggle-switch-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.leave-toggle-switch-slider {
  display: flex;
  align-items: center;
  padding: 0 5px;
  position: absolute;
  top: 5px;
  left: 10px;
  width: 70px;
  height: 35px;
  background-color: #fff;
  border-radius: 0.5rem;
  transition: transform 0.4s;
  font-weight: 300;
  z-index: 9999;
}

.leave-toggle-switch-input:checked + .leave-toggle-switch-slider {
  transform: translateX(60px); // Moves slider based on width
}

.leave-toggle-switch-input:checked ~ .leave-toggle-switch {
  background-color: #28a745;
}

.leave-toggle-switch-input:checked ~ .leave-toggle-switch::after {
  opacity: 0.5;
}

.leave-toggle-switch-input:checked ~ .leave-toggle-switch::before {
  opacity: 1;
}

.span-custom {
  position: absolute;
  right: 10px;
  top: 10px;
}

.span-fullday {
  position: absolute;
  left: 10px;
  top: 10px;
}
