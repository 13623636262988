.petty_cash_year_month_picker {
  .react-datepicker__navigation {
    top: 10px;
  }
  .react-datepicker__header {
    padding: 15px 0;
  }
  .react-datepicker__month .react-datepicker__month-text,
  .react-datepicker__month .react-datepicker__quarter-text {
    margin: 6px;
    padding: 0.5rem;
  }
  .react-datepicker__month-text--keyboard-selected {
    padding: 0.5rem;
  }
  .react-datepicker__month-text:hover{
    padding: 0.5rem;
  }
}
