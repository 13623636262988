/**=====================
  5.3 Header CSS Start
==========================**/
.left-header {
  .d-flex {
    align-items: center;
  }
  h6 {
    line-height: 1.6;
    @media (max-width: 1199px) {
      width: 160px;
      overflow: hidden;
      -webkit-line-clamp: 1;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    @media (max-width: 991px) {
      width: 120px;
    }
    @media (max-width: 767px) {
      width: 66px;
    }
  }
  @media (max-width: 755px) {
    display: none;
  }
  img {
    margin-top: -12px;
    height: 26px;
  }
  i {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: -4px;
  }
}
.onhover-show-div {
  top: 55px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    padding: 5px 30px;
    &:before {
      display: none;
    }
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
.left-header {
  display: flex;
  .left-menu-header {
    ul.header-left {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        position: relative;
        // width: 595px;
        .Typeahead-menu{
          max-height: 265px;
          top: 40px;
          width: 280px;
          [dir="rtl"] & {
            right: 0;
            left: unset;
          }
        }
        // @media (max-width: 991px) {
        //   width: 295px;
        // }
        // [dir="rtl"] & {
        //   padding: 0px 0px 0px 15px;
        // }
        @media (max-width: 1550px) and (min-width: 1200px){
          padding: 0 8px 0px 0px;
          [dir="rtl"] & {
            padding: 0 0px 0px 8px;
          }
        }
        a {
          color: $theme-body-font-color;
          font-weight: 500;
          &:hover {
            color: var(--theme-default) !important;
            letter-spacing: 1.5px;
            transition: all 0.3s ease;
          }
        }
        ul.onhover-show-div {
          min-width: 190px;
          padding: 20px 0;
          li {
            padding: 6px 25px;
          }
          li.flyout-right {
            position: relative;
            &:hover {
              ul {
                display: block;
                transition: all 0.5s ease-in;
              }
            }
            ul {
              padding: 20px 0;
              li {
                padding: 6px 25px;
              }
              li.flyout-right {
                position: relative;
                &:hover {
                  ul {
                    display: block;
                    transition: all 0.8s ease-in;
                  }
                }
                ul {
                  display: none;
                }
              }
            }
            ul {
              position: absolute;
              top: 0;
              left: calc(100% + 3px);
              min-width: 200px;
              display: none;
              background-color: $white;
              border-radius: 5px;
            }
          }
        }
        &:hover{
          span.f-w-600{
            &::before{
              width: 120px;
              transition: all 0.5s ease-in;
            }
          }
        }
        span.f-w-500 {
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primary-color;
          padding: 6px 18px;
          background-color: rgba($primary-color , 0.2);
          border-radius: 5px;
          z-index: -1;
          transition: all 0.5s ease-in-out;
          @media (max-width: 1550px) and (min-width: 1200px){
            padding: 6px 10px;
          }
        }
        span {
          font-size: 14px;
          line-height: 1.8;
          color: $theme-body-font-color;
          svg {
            width: 22px;
            height: 22px;
            fill: $primary-color;
            margin-right: 10px;
            [dir="rtl"] & {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .mega-menu-container {
    min-width: 1100px;
    position: absolute;
    width: calc(100vw - 800px);
    height: 0;
    border-radius: 0px;
    background-color: $white;
    top: 59px;
    left: -30px;
    border-top: 1px solid $light-semi-gray;
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 20px;
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    opacity: 0;
    visibility: hidden;
    display: block !important;
    transition: all 0.2s ease-in-out;
    &.show {
      opacity: 1;
      visibility: visible;
      height: 300px;
    }
    .link-section {
      > div {
        h6 {
          margin-top: 20px;
        }
      }
      li {
        width: 100%;
        padding: 7px 0 7px 15px;
        a {
          transition: all 0.3s ease;
        }
        &:hover {
          a {
            color: var(--theme-default) !important;
            letter-spacing: 1.5px;
            transition: all 0.3s ease;
          }
        }
      }
    }
    .mega-box {
      & + .mega-box {
        padding-left: 30px;
        border-left: 1px solid $light-semi-gray;
      }
      .icon {
        li {
          position: relative;
          &:hover {
            &:before {
              color: var(--theme-default);
              transition: all 0.3s ease;
            }
          }
          &:before {
            content: "\f101";
            font-family: fontawesome;
            position: absolute;
            top: 7px;
            left: 0px;
          }
        }
      }
      .svg-icon {
        & ~ div {
          h6 {
            margin-top: 10px;
            margin-bottom: 5px;
          }
        }
        li {
          padding-left: 0px;
          &:hover {
            &:before {
              color: var(--theme-default);
              transition: all 0.3s ease;
            }
          }
          a {
            svg {
              width: 16px;
              margin-right: 5px;
              vertical-align: middle;
            }
          }
        }
      }
    }
    .submenu-title {
      margin-top: 20px;
      margin-left: 20px;
    }
    .according-menu {
      display: none;
    }
  }
  .input-group {
    padding: 5px 15px;
    border-radius: 30px;
    overflow: hidden;
    background-color: $body-color;
    .input-group-prepend {
      .input-group-text {
        background-color: transparent;
        border: none;
        padding: 0;
        height: 40px;
        width: 40px;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: $primary-color;
        }
      }
    }
    .form-control {
      border: none;
      background-color: transparent;
      padding: 0;
      font-size: 14px;
      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        font-weight: 300;
        color: $light-text;
      }
    }
  }
}
/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.offcanvas,
.offcanvas-bookmark {
  .page-wrapper {
    .page-body-wrapper {
      .page-body {
        position: relative;
        filter: blur(5px) grayscale(100%);
      }
    }
  }
}
.offcanvas {
  position: unset;
  visibility: visible;
  background: unset;
  z-index: 2;
}
.toggle-sidebar {
  position: absolute;
  right: 20px;
  top: 22px;
  cursor: pointer;
  background-color: $sidebar-toggle-background;
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  svg {
    width: 18px;
    height: 18px;
  }
}
.onhover-dropdown {
  cursor: pointer;
  position: relative;
  &:hover {
    .onhover-show-div {
      @extend %for-animated-hover-box;
    }
  }
}
.onhover-show-div {
  top: 55px;
  position: absolute;
  z-index: 8;
  background-color: $white;
  transition: all linear 0.3s;
  @extend %for-animated-hover;
  li {
    a {
      svg {
        margin-top: 0 !important;
        path,
        line {
          color: $theme-body-font-color !important;
        }
      }
    }
  }
}
/**======Main Header css Ends ======**/
/*=======Mega menu css start=======*/
.mega-menu {
  .default-according {
    .card {
      .btn-link {
        font-weight: 500;
        color: gray;
      }
      .card-body {
        font-size: 12px;
      }
    }
  }
  .onhover-show-div {
    width: 1500px;
  }
  .card-body {
    padding: 20px;
  }
  div {
    >div {
      a {
        margin-bottom: 0px;
        display: inline-block;
        color: $theme-body-sub-title-color;
      }
    }
  }
  .list-unstyled {
    div {
      a {
        padding: 8px 35px 8px 0;
        transition: $sidebar-transition;
        &:hover {
          padding: 8px 35px 8px 10px;
          transition: $sidebar-transition;
        }
      }
    }
  }
}
footer {
  padding: 17px 30px 17px 30px !important;
  p {
    .footer-icon {
      width: 22px;
      height: 18px;
      vertical-align: middle;
    }
    color: $theme-body-font-color;
    font-weight: 500;
  }
}
/*=======Mega menu css end=======*/
/**=====================
    5.3 Header CSS Ends
==========================**/